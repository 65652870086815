<template>
  <div>
    <div v-if="campaigns.count() === 0">
      <div class="card">
        <div class="card-prompt">
          <svg id="Layer_1___cjshmz3ssz1lt0c153z8o293g" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 508.61 377.6" style="height: 300px;">
            <defs>
              <linearGradient id="linear-gradient___cjshmz3ssz1lt0c153z8o293g" x1="394.51" y1="120.81"
                              x2="368.61" y2="172.62" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-opacity="0"></stop>
                <stop offset="0.88" stop-opacity="0.8"></stop>
                <stop offset="0.97"></stop>
              </linearGradient>
              <linearGradient id="linear-gradient-2___cjshmz3ssz1lt0c153z8o293g" x1="208.36" y1="374.65"
                              x2="230.2" y2="374.65" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#ecc4d7"></stop>
                <stop offset="0.42" stop-color="#efd4d1"></stop>
                <stop offset="1" stop-color="#f2eac9"></stop>
              </linearGradient>
              <linearGradient id="linear-gradient-3___cjshmz3ssz1lt0c153z8o293g" x1="243.66" y1="372.45"
                              x2="265.86" y2="372.45"
                              xlink:href="#linear-gradient-2___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-4___cjshmz3ssz1lt0c153z8o293g" x1="269.49" y1="144.52"
                              x2="359.07" y2="144.52"
                              xlink:href="#linear-gradient-2___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-5___cjshmz3ssz1lt0c153z8o293g" x1="118.1" y1="152.68"
                              x2="197.17" y2="152.68"
                              xlink:href="#linear-gradient-2___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-6___cjshmz3ssz1lt0c153z8o293g" x1="248.16" y1="152.63"
                              x2="255.03" y2="152.63"
                              xlink:href="#linear-gradient-2___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-7___cjshmz3ssz1lt0c153z8o293g" x1="250.22" y1="152.33"
                              x2="264.47" y2="158.42"
                              xlink:href="#linear-gradient-2___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-8___cjshmz3ssz1lt0c153z8o293g" x1="50.59" y1="222.62"
                              x2="106.91" y2="208.35"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-9___cjshmz3ssz1lt0c153z8o293g" x1="208.36" y1="201.67"
                              x2="319.86" y2="256.48"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-10___cjshmz3ssz1lt0c153z8o293g" x1="236.78" y1="172.32"
                              x2="249.73" y2="188.36"
                              xlink:href="#linear-gradient-2___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-11___cjshmz3ssz1lt0c153z8o293g" x1="106.38" y1="308.85"
                              x2="287.7" y2="297.59"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-12___cjshmz3ssz1lt0c153z8o293g" x1="74.83" y1="185.01"
                              x2="122.89" y2="209.04"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-13___cjshmz3ssz1lt0c153z8o293g" x1="339.27" y1="175.52"
                              x2="373.06" y2="212.97"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-14___cjshmz3ssz1lt0c153z8o293g" x1="75.02" y1="133.56"
                              x2="148.23" y2="170.73"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-15___cjshmz3ssz1lt0c153z8o293g" x1="361.97" y1="99.11"
                              x2="361.97" y2="143.04"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-16___cjshmz3ssz1lt0c153z8o293g" x1="238.4" y1="126.27"
                              x2="230.51" y2="167.1"
                              xlink:href="#linear-gradient-2___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-17___cjshmz3ssz1lt0c153z8o293g" x1="372.55" y1="147.71"
                              x2="332.57" y2="164.04"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-18___cjshmz3ssz1lt0c153z8o293g" x1="358.2" y1="142.39"
                              x2="329.76" y2="162.67"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-19___cjshmz3ssz1lt0c153z8o293g" x1="344.61" y1="123.84"
                              x2="431.89" y2="215.07" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0"></stop>
                <stop offset="0.84" stop-color="#fff" stop-opacity="0.84"></stop>
                <stop offset="0.97" stop-color="#fff"></stop>
              </linearGradient>
              <linearGradient id="linear-gradient-20___cjshmz3ssz1lt0c153z8o293g" x1="218.07" y1="181.2"
                              x2="178.84" y2="204.1"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
              <linearGradient id="linear-gradient-21___cjshmz3ssz1lt0c153z8o293g" x1="220.79" y1="302.69"
                              x2="282.44" y2="298.86"
                              xlink:href="#linear-gradient___cjshmz3ssz1lt0c153z8o293g"></linearGradient>
            </defs>
            <title>58. Shopping</title>
            <path
                d="M497.38,282.19c-15.46,28.63-46.15,49.63-78.69,49.43-11.69-.08-23.45-2.73-35-.77-8,1.37-15.52,4.92-23,8.25-38.21,17.09-84.34,28.84-120.52,7.79-21.22-12.35-37.65-35.28-62-37.89-15.13-1.62-29.7,5.2-43.82,10.88-20.32,8.17-42.79,14.33-63.94,8.65-22.25-6-38.77-23.93-49.79-44.63a141.47,141.47,0,0,1-7.3-16c-17.07-44.39-15.88-97.93,12.36-136.2C47,102.74,80.8,85.66,114.8,74.06c78.05-26.62,154.22-.65,234.57-5.19,21.77-1.23,44.37-3.24,64.71,4.6,16.19,6.23,29.49,18.2,41.3,30.9,22.16,23.83,40.76,51.68,49.76,82.95a143.36,143.36,0,0,1,5.53,34.7C511.37,242.92,507.28,263.85,497.38,282.19Z"
                transform="translate(-2.14 -61.87)" fill="#68e1fd" opacity="0.19"
                style="isolation:isolate"></path>
            <path
                d="M497.38,282.19c-15.46,28.63-46.15,49.63-78.69,49.43-11.69-.08-23.45-2.73-35-.77-8,1.37-15.52,4.92-23,8.25-38.21,17.09-84.34,28.84-120.52,7.79-21.22-12.35-37.65-35.28-62-37.89-15.13-1.62-29.7,5.2-43.82,10.88-20.32,8.17-42.79,14.33-63.94,8.65-22.25-6-38.77-23.93-49.79-44.63,8.43-3.86,18.51-1.82,27.84-.5a157.08,157.08,0,0,0,83.33-11c27.89-11.94,57-31.75,88.76-24.53,31,7,60.42,30.9,93.58,26,12.35-1.82,23.54-8.28,33.49-15.8,22.27-16.84,39.85-39.41,62.84-55.25s55.19-23.75,78.36-8.17c9.86,6.63,16.7,16.55,21.84,27.41C511.37,242.92,507.28,263.85,497.38,282.19Z"
                transform="translate(-2.14 -61.87)" fill="#68e1fd" opacity="0.19"
                style="isolation:isolate"></path>
            <polygon points="377.13 111.35 401.85 150.35 383.1 161.82 358.54 167.17 377.13 111.35"
                     fill="#68e1fd"></polygon>
            <polygon points="377.13 111.35 401.85 150.35 383.1 161.82 358.54 167.17 377.13 111.35"
                     fill="url(#linear-gradient___cjshmz3ssz1lt0c153z8o293g)"></polygon>
            <path
                d="M208.36,337.81c.27.05,11.15,72.84,11.15,72.84s7.91,1.9,7.63,0,1.09-25.18,2.71-29.78-3-43.06-3-43.06Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-2___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path d="M243.66,337.32s1.63,36.78,4.87,46,7.31,28.19,7.31,28.19h10L264,336.55S252.6,329,243.66,337.32Z"
                  transform="translate(-2.14 -61.87)"
                  fill="url(#linear-gradient-3___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M217.44,405.75c2.48-.32,5,.74,7.47.47a12,12,0,0,1,3.15-.31.69.69,0,0,1,.44.2.72.72,0,0,1,.11.48l0,3.3c0,1.59,0,3.33-1,4.58s-2.86,1.7-4.53,1.84a3.5,3.5,0,0,1-1.61-.13,3.75,3.75,0,0,1-1.3-1.05c-1.11-1.21-1.95-1.91-2.1-3.54C218.05,411.07,217.52,405.75,217.44,405.75Z"
                transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path
                d="M265.05,407.2a1.05,1.05,0,0,1,1.27,1.22,30.52,30.52,0,0,1-.46,7.07,5,5,0,0,1-.5,1.8c-.72,1.26-2.33,1.64-3.77,1.72-2.19.14-4.67-.27-6-2a8.3,8.3,0,0,1-1.17-3.53c-.23-1.3-.69-2.55-.9-3.84-.19-1.11.1-1.65,1.23-1.81,1.59-.24,3.25-.27,4.86-.37C261.44,407.32,263.25,407.24,265.05,407.2Z"
                transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path
                d="M199.75,433.74a3.5,3.5,0,0,0,.18,1.84,4.14,4.14,0,0,0,2.6,2.08c3.64,1.25,14.78-6.34,18.3-7.89a52.37,52.37,0,0,0,8.85-4.77c.28-.2-.45-10-.65-11.49a.84.84,0,0,0-.24-.61,1,1,0,0,0-.48-.14c-1.68-.14-3.37,0-5.06,0a22.44,22.44,0,0,1-2.59-.11,12.35,12.35,0,0,0-2.36-.43c-1.28.14-3.74,4.16-4.85,4.78C200.76,424.11,200.09,431.36,199.75,433.74Z"
                transform="translate(-2.14 -61.87)" fill="#25233a"></path>
            <path
                d="M253.41,422.27a8.7,8.7,0,0,0-.24,2.91c.29,1.51,1.6,2.59,2.83,3.52,2.25,1.69,14.51,13.94,21.64,10a2.75,2.75,0,0,0,1-1.24,5.13,5.13,0,0,0,.45-1.82,13.4,13.4,0,0,0-1.71-7.88c-1.22-2.1-8.54-8.51-9.28-10.82-.45-1.4-.59-3.11-1.84-3.9a1,1,0,0,0-.58-.19,1.58,1.58,0,0,0-.85.39c-2.18,1.46-5,1.08-7.5.72-.73-.11-2.28-1-2.88-.6-.89.62-.78,3.1-.82,4A41.78,41.78,0,0,1,253.41,422.27Z"
                transform="translate(-2.14 -61.87)" fill="#25233a"></path>
            <path
                d="M269.49,166.71s33.94-21.14,34.48-21.6,34.84-28.34,38.75-32.25,6.89-10.56,12.36-8.4,5.59,10.69-1.38,18.46c-.83.93-6.26.81-6.46,1.06-.47.56-1.48,1.65-4.51,5s-21.58,23.47-32.36,28.63S278.71,185,278.71,185Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-4___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M197.17,169.47c-1.37,1.6-31.71-15.48-31.71-15.48l-35.81-36.41s-6.16-5.37-10.53,2,6.61,14.56,6.61,14.56l7.17,1.16s17.38,28.9,21.67,30.35,35.75,27,36.05,23.66S197.17,169.47,197.17,169.47Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-5___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M212.07,136.71c-.94,9.52.76,19.77,7,27,1.47,1.72,3.26,3.42,3.52,5.67a17.69,17.69,0,0,1-.21,3.32,4,4,0,0,0,.86,3.11,1.85,1.85,0,0,0,2.85-.22l2.54,5.37a2.39,2.39,0,0,0,1,1.25,2.22,2.22,0,0,0,1.35,0,20.19,20.19,0,0,0,7.9-3.3,2.54,2.54,0,0,1,1-.52c1.65-.26,2,2.55,3.59,3.16,1.2.47,2.42-.57,3.29-1.52l3.46-3.75a5.75,5.75,0,0,0,2.88,2.81,12.89,12.89,0,0,1,1.55-3,15.54,15.54,0,0,0,.58,5.51l1.75-2.17c.16-.21.37-.44.64-.42a.85.85,0,0,1,.47.26l2.86,2.53c-1.48-4.37-.82-9.15.22-13.63s2.45-9,2.44-13.56c0-5.91-2.36-11.53-4.66-17a29.69,29.69,0,0,0-3.8-7.12c-2.34-3-5.68-4.9-9-6.65a28.09,28.09,0,0,0-9.17-3.4,29.71,29.71,0,0,0-7.59.25q-4,.48-8.05,1c-2.32.33-3.36.46-4.84,2.28C213.59,127.63,212.51,132.25,212.07,136.71Z"
                transform="translate(-2.14 -61.87)" fill="#25233a"></path>
            <path
                d="M255,149.79a3.72,3.72,0,0,1-.37,2.42,16.68,16.68,0,0,1-1.34,2.11,13.07,13.07,0,0,1-1.33,1.8,3,3,0,0,1-1.95,1c-2.82.09-1.72-4.63-.78-6.11C250.26,149.28,254.15,146.32,255,149.79Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-6___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M251.1,151.75a6.29,6.29,0,0,0,.57-1.05,1.94,1.94,0,0,1,.77-.9.89.89,0,0,1,1.09.14c.32.4.11,1-.14,1.42-.58,1-.56,2.62-1.47,3.36a6.9,6.9,0,0,1-1.48.76,4.18,4.18,0,0,1-2,0c-.27-.2.34-1.55.31-1.89s0-.84.11-1,.59,0,.79,0A2.1,2.1,0,0,0,251.1,151.75Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-7___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path d="M76.32,181.37,50.13,213l38.95,34.61,11.07-10,3.71-53.13C104,183,76.32,181.37,76.32,181.37Z"
                  transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path d="M76.32,181.37,50.13,213l38.95,34.61,11.07-10,3.71-53.13C104,183,76.32,181.37,76.32,181.37Z"
                  transform="translate(-2.14 -61.87)"
                  fill="url(#linear-gradient-8___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M279.55,183.32a3.31,3.31,0,0,1-.84,1.69,55.7,55.7,0,0,1-5.34,6.33c-3,2.91-6.69,5.25-8.68,8.92-2.84,5.25-.65,53.68-.44,62.31.06,2.47-38.27,4-55.07,2.17.22-8.87,5.29-24.31,3.47-33-1.47-7-2.58-3.06-6.38-14.47-1.56-4.66-.4-10.28.43-15.13.55-3.27-14.16-9.18-16.08-12.8a41.89,41.89,0,0,1,.94-7v0a42.63,42.63,0,0,1,4.23-11.29,3.52,3.52,0,0,1,1.37-1.6,3.65,3.65,0,0,1,2.29-.08,80.57,80.57,0,0,1,16.87,5c5.08,2.12,10.36,1.21,15.71.49a128.93,128.93,0,0,0,38.33-11.42q4.36,9.1,8.72,18.19A3.32,3.32,0,0,1,279.55,183.32Z"
                transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path
                d="M279.55,183.32a3.31,3.31,0,0,1-.84,1.69,55.7,55.7,0,0,1-5.34,6.33c-3,2.91-6.69,5.25-8.68,8.92-2.84,5.25-.65,53.68-.44,62.31.05,2.14-28.69,3.57-47.31,2.73l31.22-2.39s13.24-46.46,9.92-58.84c-3-11.19,10-24.19,18.08-28.49l2.93,6.11A3.32,3.32,0,0,1,279.55,183.32Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-9___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M231.92,167.79c-.11-1.44-.32-3,.4-4.24a6.34,6.34,0,0,1,3.23-2.26c2.38-1,5.31-1.95,7.37-.4a6.38,6.38,0,0,1,2.06,3.83,33.64,33.64,0,0,1,.53,9.4c-.16,2.76-.53,5.69-2.31,7.8a14,14,0,0,1-4.22,3c-2.14,1.14-4.94,2.23-6.8.67C226.62,181,232.34,173.62,231.92,167.79Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-10___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M209.18,264.74l-12.54,75.61s12.84,2.36,42.53-1.11c16.6-1.93,28.61-1.36,36-.48a5.94,5.94,0,0,0,6.46-7.37l-17.35-68.82S213.43,257.61,209.18,264.74Z"
                transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path
                d="M275.14,338.76c-3.05-.37-6.89-.67-11.52-.77a177.41,177.41,0,0,0-24.45,1.25c-29.69,3.47-42.53,1.11-42.53,1.11l12.54-75.61c2.69-4.52,24.09-4.18,39.4-3.33h.19c7.89.44,14.12,1,15.28,1.13l.2,0,0,.06.27,1.08,17.06,67.68A5.94,5.94,0,0,1,275.14,338.76Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-11___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path d="M118.47,125.57,97.41,169.13h1.91l19.8-42.42S119.5,125.57,118.47,125.57Z"
                  transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path d="M119.12,171l6.61-36.81,1.53.25-6.5,38.07S119.23,173.83,119.12,171Z"
                  transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path
                d="M76.77,167s37-5.24,55.5,12.27l4.73,5.12-15.67,41s-8.66,6.1-17,4.38S80.45,224,80.45,224s-5-1.6-5-9.23S76.77,167,76.77,167Z"
                transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path
                d="M76.77,167s37-5.24,55.5,12.27l4.73,5.12-15.67,41s-8.66,6.1-17,4.38S80.45,224,80.45,224s-5-1.6-5-9.23S76.77,167,76.77,167Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-12___cjshmz3ssz1lt0c153z8o293g)"></path>
            <polygon points="345.1 62.11 337.02 116.8 338.2 116.8 346.31 62.11 345.1 62.11"
                     fill="#68e1fd"></polygon>
            <polygon points="351.56 61.05 360.93 106.26 362.68 105.95 352.48 59.94 351.56 61.05"
                     fill="#68e1fd"></polygon>
            <path
                d="M331.92,182.86s23-19.57,42.4-14.73h6.28s-3.81,37.11-2.54,46.33-27.46,18.89-29.62,18.38-10.29.51-11.31-11.44S331.92,182.86,331.92,182.86Z"
                transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <path
                d="M331.92,182.86s23-19.57,42.4-14.73h6.28s-3.81,37.11-2.54,46.33-27.46,18.89-29.62,18.38-10.29.51-11.31-11.44S331.92,182.86,331.92,182.86Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-13___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path d="M119.12,126.71l-3.72,52h1.86l3-52S119.46,125.34,119.12,126.71Z"
                  transform="translate(-2.14 -61.87)" fill="#68e1fd"></path>
            <polygon points="119.85 69.15 130.77 115.86 133.12 115.2 121.23 70.47 119.85 69.15"
                     fill="#68e1fd"></polygon>
            <polygon
                points="102.15 116.51 115.13 116.8 141.8 113.75 155.5 166.4 118.16 185.71 97.57 182.13 102.15 116.51"
                fill="#68e1fd"></polygon>
            <polygon points="118.16 185.71 115.13 116.8 102.15 116.51 97.57 182.13 118.16 185.71"
                     fill="url(#linear-gradient-14___cjshmz3ssz1lt0c153z8o293g)"></polygon>
            <polygon
                points="341.66 127.53 348.09 128.68 359.3 131.22 382.28 120.28 362.68 117.67 341.66 127.53"
                fill="#68e1fd"></polygon>
            <polygon
                points="341.66 127.53 348.09 128.68 359.3 131.22 382.28 120.28 362.68 117.67 341.66 127.53"
                fill="url(#linear-gradient-15___cjshmz3ssz1lt0c153z8o293g)"></polygon>
            <polygon
                points="382.28 120.28 359.3 131.22 341.66 127.53 343.89 176.82 359.3 182.13 383.24 169.19 382.28 120.28"
                fill="#68e1fd"></polygon>
            <path
                d="M222.72,161.44c2.85,5.33,9,9.41,14.87,7.82,4.37-1.19,7.43-5.16,9.3-9.28a57.74,57.74,0,0,0,3.56-11.84c.85-3.89,1.6-7.91.86-11.82a16.26,16.26,0,0,0-16.93-12.68C217,125.32,216.45,149.68,222.72,161.44Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-16___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M231.23,111.59c7.06-1.5,15-.23,20.38,4.54s7.87,12.66,13.64,17.22c1.86,1.47,4.31,2.58,6.58,1.88a7.92,7.92,0,0,1-7,4.53,7.29,7.29,0,0,0,3.54,2,8.39,8.39,0,0,1-6.26,3.24,3.1,3.1,0,0,1,1.16,1.89c-2.43,1.25-5.34,1.13-8,.56a16.92,16.92,0,0,1-10.88-7.79c-5.7-10.07-15.68-17.44-23.64-4.82-2.48-1.65-5.12-3.55-5.53-6.49C214.06,119.63,223.91,113.14,231.23,111.59Z"
                transform="translate(-2.14 -61.87)" fill="#25233a"></path>
            <polygon points="346.31 62.11 346.31 128.68 348.09 128.68 347.2 61.9 346.31 62.11"
                     fill="#68e1fd"></polygon>
            <polygon points="349.39 61.7 367.53 120.99 369.21 120.99 350.66 61.48 349.39 61.7"
                     fill="#68e1fd"></polygon>
            <polygon points="359.3 131.22 359.3 182.13 349.39 175.32 348.09 128.68 359.3 131.22"
                     fill="url(#linear-gradient-17___cjshmz3ssz1lt0c153z8o293g)"></polygon>
            <polygon points="343.89 176.82 349.39 175.32 348.09 128.68 341.66 127.53 343.89 176.82"
                     fill="url(#linear-gradient-18___cjshmz3ssz1lt0c153z8o293g)"></polygon>
            <polygon points="382.28 120.28 359.3 131.22 359.3 182.13 369.21 176.78 382.28 120.28"
                     fill="url(#linear-gradient-19___cjshmz3ssz1lt0c153z8o293g)"></polygon>
            <path
                d="M206.7,202.15c.55-3.27-14.16-9.18-16.08-12.8a41.89,41.89,0,0,1,.94-7v0s15.54,6.79,16.39,10.73S206.7,202.15,206.7,202.15Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-20___cjshmz3ssz1lt0c153z8o293g)"></path>
            <path
                d="M275.14,338.76c-3.05-.37-6.89-.67-11.52-.77l-12-42.42s-3.92-30.87-3-34.16h.19l15.28,1.13.2,0,0,.06.27,1.08,17.06,67.68A5.94,5.94,0,0,1,275.14,338.76Z"
                transform="translate(-2.14 -61.87)"
                fill="url(#linear-gradient-21___cjshmz3ssz1lt0c153z8o293g)"></path>
          </svg>
          <h4>Create your first discount campaign</h4>
          Get started with quantity discounts. Create your first campaign today in 4 simple steps!

        </div>
        <div class="card-action-center">
          <b-link class="btn" href="#/campaigns">Create your first campaign</b-link>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="card w-100">
          <b-tabs small card>
            <b-tab title="Revenue" title-item-class="card-header-tab">
                      <span class="text-muted float-right">
                        Last 30 days
                      </span>
              <h4>Revenue From Discount Campaigns</h4>
              <div class="display-2 mb-2">{{ analytics.revenue_format }}</div>
              <column-chart :data="analytics.daily_revenue"></column-chart>

              <small class="text-muted">The figures and chart above represent your revenue and sales resulting from carts
                using a discount code generated by Smarty in the last 30 days</small>
            </b-tab>
            <b-tab title="Sales" title-item-class="card-header-tab">
                      <span class="text-muted float-right">
                        Last 30 days
                      </span>
              <h4>Sales Using Discount</h4>
              <div class="display-2 mb-2">{{ analytics.sales }}</div>
              <column-chart :data="analytics.daily_sales"></column-chart>

              <small class="text-muted">The figures and chart above represent your revenue and sales resulting from carts
                using a discount code generated by Smarty in the last 30 days</small>
            </b-tab>
          </b-tabs>
        </div>
        <div class="alert alert-info">😍 Your feedback gives us life - please do <a href="https://apps.shopify.com/smarty-discounts" target="_blank">write us a review on Shopify - we read every one!</a></div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <h4>Active Campaigns</h4>
              <div class="display-2">{{ stats.running }}</div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <h4>Total Product Views</h4>
              <div class="display-2">{{ campaigns.sum('product_counter') }}</div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <h4>Total Cart Views</h4>
              <div class="display-2">{{ campaigns.sum('cart_counter') }}</div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <h4>Total Checkouts</h4>
              <div class="display-2">{{ campaigns.sum('checkout_counter') }}</div>
            </div>
          </div>
        </div>

      </div>
      <div class="card">
        <div class="display-2 card-body">Your discount campaigns</div>
        <div v-for="campaign in campaigns.sortByDesc('checkout_counter').all()">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h4>
                  <b-link :href="'#/edit/'+campaign.id" class="display-2" @click="trackView(campaign)">{{
                      campaign.title
                    }}
                  </b-link>
                </h4>
                <div class="badge badge--success" v-if="campaign.status">Active</div>
                <div class="badge" v-if="!campaign.status">Paused</div>
              </div>
              <div class="col-sm-2 text-center">
                <div class="display-3">{{ campaign.product_counter }}</div>
                <div class="small text-uppercase text-muted">Product Page</div>
              </div>
              <div class="col-sm-2 text-center">
                <div class="display-3">{{ campaign.cart_counter }}</div>
                <div class="small text-uppercase text-muted">Cart Page</div>
              </div>
              <div class="col-sm-2 text-center">
                <div class="display-3">{{ campaign.checkout_counter }}</div>
                <div class="small text-uppercase text-muted">Checkouts</div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!--<div class="card mt-5">
        <div class="card-body" v-if="themeId">
          <button class="float-right btn btn-primary" @click="customizeTheme"></button>
          <h4>Customize your discounts' appearance</h4>
          To alter the look and feel of your discounts, click Customize Theme then choose Mamuta Discounts App
        </div>
      </div>-->
    </div>

  </div>
</template>

<script>
import collect from 'collect.js'
import {Redirect} from '@shopify/app-bridge/actions'
import vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'

vue.use(VueChartkick, {adapter: Chart})
// Install BootstrapVue
vue.use(BootstrapVue)

export default {
  name: "Dashboard",
  data() {
    return {
      stats: {},
      campaigns: collect([]),
      analytics: {},
      themeId: null,
      shop: {}
    }
  },
  methods: {
    trackView(campaign) {
      window.analytics.page('Edit Campaign', {path: '/edit/' + campaign.id})
    },
    customizeTheme() {
      const redirect = Redirect.create(this.appBridge)
      redirect.dispatch(Redirect.Action.ADMIN_PATH, {
        path: `/themes/${this.themeId}/editor`,
        newContext: true,
      })
    }
  },
  mounted() {
    axios.get('/stats/' + this.shopUrl.replace('https://', '')).then((response) => {
      console.log(response.data)
      this.stats = response.data.tierPricesStat;
      this.campaigns = collect(response.data.product)
      this.analytics = response.data.analytics
      this.shop = response.data.shop
      this.analytics.revenue_format = new Intl.NumberFormat('en',{style:'currency',currency: this.shop.currency}).format(this.analytics.revenue)

      console.log(this)
    })
    axios.get('/settings/customisetheme/' + this.shopUrl.replace('https://', '')).then((response) => {
      this.themeId = response.data
    })
    console.log(this)
  },
  props: ['shopSettings', 'shopUrl', 'appBridge'],

}
</script>

<style scoped>

</style>
