import React, { useReducer, useEffect, useContext } from 'react'
import * as PropTypes from 'prop-types'
import { Button } from '@shopify/polaris'
import { SaveMinor, CancelSmallMinor } from '@shopify/polaris-icons'

import { RootContext } from '@/components'
import { Moments, Strings, Numbers } from '@/components/utilities'
import { BackendAPI } from '@/api'
import {
  CampaignGeneral,
  CampaignDisplay,
  CampaignSchedule,
  CampaignSpecials,
  CampaignOptions,
  CampaignType,
  hasErrorStates,
} from '@/components/editor/sections'
import { CampaignLevels } from '@/components/editor/sections'

const initialState = {
  campaign: {}, // the tier price data (single object)
  searchValue: '', // search value for the "select product/collection" dialog
  fieldErrors: { // Holds the form fields error info: {fieldName: error text}
    title: ''
  },
  hasErrors: false,
  isDirty: false, // something has been changed
  startDate: null,
  endDate: null,
  setEndDate: false,
  timezone: null,
  themeId: '',
  showItemsList: true,
}

const initState = (values) => {
  const newState = _.merge(initialState, values)
  return {
    ...newState,
  }
}

export const SET_STATE_ACTION = 'setAll'
export const SET_CAMPAIGN_ACTION = 'campaign'
export const TOGGLE_SHOW_ITEMS_ACTION = 'showItemsList'
export const RESET_STATE_ACTION = 'reset'

const reducer = (state, action) => {
  switch (action.type) {
    case SET_STATE_ACTION:
      // const newState = _.merge(state, action.payload)
      return {
        ...state,
        ...action.payload
      }
    case SET_CAMPAIGN_ACTION:
      const isDirty = !_.isEqual(state[action.type], action.payload)
      return isDirty
        ? {
          ...state,
          [action.type]: action.payload,
          isDirty,
        }
        : state
    case TOGGLE_SHOW_ITEMS_ACTION:
      return {
        ...state,
        [action.type]: action.payload,
      }
    case RESET_STATE_ACTION:
      return initState(action.payload)
    default:
      return state
  }
}

export const CampaignContext = React.createContext({})

/**
 * Contains top level functionality for the single Tier Price
 * Holds the data of the tier price.
 * Receives the data using a function inherited from the props.
 */
export const Campaign = (props) => {
  const tmpState = {
    startDate: Moments.getCurrentMomentLocal(null),
    timezone: Moments.getLocalTZ(),
  }
  const [state, dispatch] = useReducer(reducer, tmpState, initState)
  const rootContext = useContext(RootContext)

  const { history, match, shopSettings, shopUrl } = props

  useEffect(() => {
    pricelogger('Campaign useEffect')
    pricelogger(shopSettings)
    const fetchData = async () => {
      try {
        const response = await rootContext.getItem(match.params.id)
        pricelogger('got item', match.params.id, response.data)
        response.data.tz = Moments.getLocalTZ(response.data.tz)
        response.data.startsAt = Moments.makeLocalTime(response.data.startsAt, Moments.TIMEZONE_GMT, false) // _.isEmpty(response.data.startsAt) ? moment().format('YYYY-MM-DD HH:mm:ss') : response.data.startsAt
        response.data.endsAt = Moments.makeLocalTime(response.data.endsAt, Moments.TIMEZONE_GMT, true) // _.isEmpty(response.data.endsAt) ? null : response.data.endsAt
        pricelogger(response.data)
        pricelogger('getCustomiseThemeUrl ' + shopUrl)
        const host = Strings.getHostName(shopUrl)
        const themeUrl = await BackendAPI.customiseTheme(host)
        const themeId = themeUrl.data + ''
        dispatch({
          type: SET_STATE_ACTION, payload: {
            campaign: response.data,
            setEndDate: !_.isNull(response.data.endsAt),
            startDate: Moments.convertToLocal(response.data.startsAt, response.data.tz), // moment(response.data.startsAt, 'YYYY-MM-DD HH:mm:ss'),
            endDate: _.isNull(response.data.endsAt) ? null : Moments.convertToLocal(response.data.endsAt, response.data.tz),
            timezone: response.data.tz,
            themeId,
            isDirty: false,
          }
        })
      } catch (error) {
        pricelogger('got error')
        pricelogger(error)
        dispatch({ type: SET_CAMPAIGN_ACTION, payload: {} })
      }
    }
    fetchData()
  }, [match.params.id])

  const cancelEdit = () => {
    rootContext.setItem({})
    history.goBack()
  }

  /**
   * Save the Campaign to backend
   */
  const saveCampaign = () => {
    pricelogger('saving...')
    const fieldErrors = _.cloneDeep(state.fieldErrors)
    fieldErrors.tiers = _.isEmpty(state.campaign.tiers) ? 'Tiers should not be empty' : ''
    fieldErrors.title = _.isEmpty(state.campaign.title) || _.isNull(state.campaign.title) || _.isUndefined(state.campaign.title) ? 'Title should not be empty' : ''
    const hasErrors = hasErrorStates(fieldErrors)
    if (hasErrors) {
      dispatch({
        type: SET_STATE_ACTION, payload: {
          fieldErrors,
          hasErrors,
        }
      })
      rootContext.showNotification('Please, fix the wrong inputs!', true)
      return
    }
    const clonedCampaign = _.cloneDeep(state.campaign)
    const tiers = clonedCampaign.tiers
    for (let i = 0; i < tiers.length; ++i) {
      tiers[i].prerequisiteQuantity = Numbers.normalizeDecimal(tiers[i].prerequisiteQuantity)
      tiers[i].value = Numbers.normalizeDecimal(tiers[i].value)
    }
    clonedCampaign.tiers = tiers
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
    rootContext.setItem(state.campaign, true)
      .then(response => {
        pricelogger('Campaign saved data')
        response.data.tz = Moments.getLocalTZ(response.data.tz)
        response.data.startsAt = Moments.makeLocalTime(response.data.startsAt, Moments.TIMEZONE_GMT, false) // _.isEmpty(response.data.startsAt) ? moment().format('YYYY-MM-DD HH:mm:ss') : response.data.startsAt
        response.data.endsAt = Moments.makeLocalTime(response.data.endsAt, Moments.TIMEZONE_GMT, true) // _.isEmpty(response.data.endsAt) ? null : response.data.endsAt
        pricelogger(response.data)
        dispatch({
          type: SET_STATE_ACTION, payload: {
            isDirty: false,
            campaign: response.data,
            setEndDate: !_.isNull(response.data.endsAt),
            startDate: Moments.convertToLocal(response.data.startsAt, response.data.tz), // moment(response.data.startsAt, 'YYYY-MM-DD HH:mm:ss'),
            endDate: _.isNull(response.data.endsAt) ? null : Moments.convertToLocal(response.data.endsAt, response.data.tz),
            timezone: response.data.tz,
          }
        })
        rootContext.showNotification('Campaign saved')
      })
      .catch(error => {
        pricelogger(error)
        rootContext.showNotification('Cannot save Campaign', true)
      })
  }

  return (
    <CampaignContext.Provider value={{ campaign: state.campaign, dispatch }}>
      <div className='TierPriceEditor__Wrapper'>
        <div className='TierPriceUx__Wrapper'>
          <CampaignGeneral fieldErrors={state.fieldErrors} themeId={state.themeId} history={history}/>
          <CampaignDisplay themeId={state.themeId}/>
          <CampaignOptions/>
          <CampaignLevels fieldErrors={state.fieldErrors}/>
          <CampaignType showItemsList={state.showItemsList}/>
          <CampaignSchedule timezone={state.timezone} startDate={state.startDate} endDate={state.endDate}
                            setEndDate={state.setEndDate}/>
          <CampaignSpecials/>
          <div className='CampaignActions'>
            <Button
              icon={CancelSmallMinor}
              onClick={cancelEdit}
            >
              {'Cancel'}
            </Button>
            <Button primary
                    icon={SaveMinor}
                    disabled={state.hasErrors || !state.isDirty}
                    onClick={saveCampaign}
            >
              {'Save'}
            </Button>
          </div>
        </div>
      </div>
    </CampaignContext.Provider>
  )
}

Campaign.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  shopUrl: PropTypes.string,
  shopSettings: PropTypes.object,
}
