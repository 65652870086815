import React from 'react'
import { TextContainer, Modal } from '@shopify/polaris'

export const TutorialModal = () => {
  return (
    <div className='Dashboard-ModalTutorial__Content'>
      <Modal.Section>
        <TextContainer>
          <p>
          Sample text 1
          </p>
          <p>
            Sample text 2
          </p>
        </TextContainer>
      </Modal.Section>
      <Modal.Section>
        <p>Next section</p>
      </Modal.Section>
    </div>
  )
}
