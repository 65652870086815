import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'
import {
  Heading, Tooltip, Select, Button,
} from '@shopify/polaris'

import { CampaignContext, ProductPageTables, ProductPageTableSettings, SET_CAMPAIGN_ACTION } from '@/components/editor'
import { gotoThemeCustomization } from '@/components/editor/sections'
import { productPageTablesSelectOptions } from '@/components/editor/tables'
import { Context } from '@shopify/app-bridge-react'

export const CampaignDisplay = (props) => {
  const appBridge = useContext(Context)
  const campaignContext = useContext(CampaignContext)
  const { campaign, dispatch } = campaignContext

  const { themeId } = props

  const handleChangeProductTableHandle = (selected, id) => {
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign.settings.productTableHandle = selected
    clonedCampaign.isPrice = selected.toLowerCase().indexOf('price') > -1
    clonedCampaign.discountType = clonedCampaign.isPrice ? 'amount' : clonedCampaign.discountType
    clonedCampaign.isBundle = clonedCampaign.isPrice ? false : clonedCampaign.isBundle
    clonedCampaign.isEachItem = clonedCampaign.isPrice ? true : clonedCampaign.isEachItem
    clonedCampaign.appliesTo = clonedCampaign.isPrice && clonedCampaign.appliesTo === 'order' ? 'products' : clonedCampaign.appliesTo
    const tiers = clonedCampaign.tiers
    let checkSuffix = 'off'
    let changeSuffix = 'Each'
    let checkInfix = 'get'
    let changeInfix = 'For'
    if (!clonedCampaign.isPrice) {
      checkSuffix = 'each'
      changeSuffix = 'Off'
      checkInfix = 'for'
      changeInfix = 'Get'
    }
    for (let i = 0; i < tiers.length; i++) {
      if (tiers[i].suffix.toLowerCase() === checkSuffix) {
        tiers[i].suffix = changeSuffix
      }
      if (tiers[i].infix.toLowerCase() === checkInfix) {
        tiers[i].infix = changeInfix
      }
    }
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  const saveProductPagePresentation = (data) => {
    const clonedCampaign = _.cloneDeep(campaign)
    _.assign(clonedCampaign.settings, data)
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  return (
    <div className='CampaignDisplay'>
      <Heading>Campaign Display</Heading>
      <div className='ProductPageTemplates'>
        <Tooltip
          content='If one of the "Price per item" tables is selected then ALL products have THE SAME PRICE as set in the "Levels"'>
          <Select
            label='Product Page Table Template'
            options={productPageTablesSelectOptions}
            onChange={handleChangeProductTableHandle}
            value={campaign && campaign.settings ? campaign.settings.productTableHandle : 'basic'}
          />
        </Tooltip>
      </div>
      <div className='ProductPageCustomize'>
        <Button fullWidth primary external onClick={() => gotoThemeCustomization(themeId, appBridge)}
                disabled={_.isEmpty(themeId) || campaign.id === 0}>
          Customise Visual Settings
        </Button>
      </div>
      <ProductPageTables
        handle={campaign && campaign.settings ? campaign.settings.productTableHandle : 'basic'}/>
      {
        campaign && campaign.settings
          ? <ProductPageTableSettings
            showDescription={campaign.settings.productTableShowDescription}
            productTableHeader={campaign.settings.productTableHeader}
            showEntireOrderWarning={campaign.settings.productTableShowEntireOrderWaring}
            entireOrderWarning={campaign.settings.productTableEntireOrderWaring}
            breaksHeader={campaign.settings.productTableBreaksHeader}
            discountsHeader={campaign.settings.productTableDiscountsHeader}
            maxBreaksNumber={campaign.settings.productTableMaxBreaksNumber}
            hiddenBreaksWarning={campaign.settings.productTableHiddenBreaksWarning}
            onUpdate={saveProductPagePresentation}
          />
          : null
      }
    </div>
  )
}

CampaignDisplay.propTypes = {
  themeId: PropTypes.any,
}
