import React from 'react'
import * as PropTypes from 'prop-types'
import { Link, DataTable, Heading } from '@shopify/polaris'

import { Moments } from '@/components/utilities'

export const TopTierInfo = (props) => {
  const { history, title, total, data } = props
  const rows = data.map(row => {
    return [
      <Link onClick={() => history.push('/edit/' + row.id)}>{row.title}</Link>,
      row.count,
      <span className='Dashboard__Performance--status'>
        {
          Moments.getBadge((row.status + '' === '1' || row.status), row.startsAt, row.endsAt, row.tz)
        }
      </span>,
    ]
  })
  return (
    <div className='Dashboard__Performance--table'>
      <Heading element='h4'>{title}</Heading>
      <DataTable
        columnContentTypes={[
          'text',
          'numeric',
          'text',
        ]}
        headings={[
          'Title',
          'Views',
          'Status',
        ]}
        rows={rows}
        footerContent={`Showing top ${rows.length} of ${total} campaigns`}
      />
    </div>
  )
}

TopTierInfo.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  data: PropTypes.array,
  history: PropTypes.object.isRequired
}
