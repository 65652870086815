import React from 'react'
import { TextStyle, Badge, Button, Scrollable } from '@shopify/polaris'
import * as PropTypes from 'prop-types'
import { Moments } from '@/components/utilities'

/**
 * Represents a single Campaign in the ResourceList
 */
export const TierPriceListItem = (props) => {
  const { id, title, description, prerequisiteCount, discountType, startsAt, endsAt, tz, appliesTo, isActive, customActions } = props

  const infoBlock = (
    <div className='RuleListItem__Info'>
      <h3 className='RuleListItem__Title'>
        <TextStyle variation='strong'>{title}</TextStyle>
        <span className='RuleListItem__Status'>
          {
            Moments.getBadge(isActive, Moments.makeLocalTime(startsAt, tz, false), Moments.makeLocalTime(endsAt, tz, true), tz)
          }
        </span>
        <span className='RuleListItem__Status'>
          {
            appliesTo === 'order' ? <Badge status='attention'>entire order</Badge> : null
          }
        </span>
      </h3>
      <Scrollable shadow style={{ height: '100px' }}>
        {description}
      </Scrollable>
    </div>
  )

  const discTypeBlock = (
    <TextStyle variation='subdued'>
      <div className='RuleListItem__DiscountType'>
        <div className='RuleListItem__DiscountType--prerequisite'>
          {'Breaks: ' + prerequisiteCount}
        </div>
        <div className='RuleListItem__DiscountType--discount'>
          {'Discount: ' + discountType}
        </div>
      </div>
    </TextStyle>
  )

  return (
    <div className='RuleListItem'>
        <div className='RuleListItem__Main'>
          {infoBlock}
          {discTypeBlock}
        </div>
        <div className='RuleListItem__CustomAction'>
          {
            customActions
              .filter(action => (action.name !== 'deactivate' && action.name !== 'activate') || (action.name === 'deactivate' && isActive) || (action.name === 'activate' && !isActive))
              .map(
                (action, index) => (
                  <Button
                    id={id}
                    key={id + ':' + index}
                    children={action.name}
                    accessibilityLabel={`Item action ${action.name}`}
                    onClick={action.onAction}
                    plain
                  />
                )
              )
          }
        </div>
    </div>
  )
}

TierPriceListItem.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  prerequisiteCount: PropTypes.string,
  discountType: PropTypes.string,
  startsAt: PropTypes.string,
  endsAt: PropTypes.string,
  tz: PropTypes.string,
  customActions: PropTypes.array,
  appliesTo: PropTypes.string,
  isActive: PropTypes.bool,
}
