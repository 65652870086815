import React from 'react'
import {
  Card,
  FormLayout,
  Checkbox,
  TextField,
} from '@shopify/polaris'

import * as PropTypes from 'prop-types'

export const CartMessage = (props) => {
  const { title, showMessage, showPercent, savedAmountMessage, onUpdate } = props

  const handleShowMessageChange = (showMessage) => {
    const { showPercent, savedAmountMessage } = props
    onUpdate(
      { showMessage, showPercent, savedAmountMessage }
    )
  }

  const handleShowPercentChange = (showPercent) => {
    const { showMessage, savedAmountMessage } = props
    onUpdate(
      { showMessage, showPercent, savedAmountMessage }
    )
  }

  const handleSavedAmountMessageChange = (savedAmountMessage) => {
    const { showMessage, showPercent } = props
    onUpdate(
      { showMessage, showPercent, savedAmountMessage }
    )
  }

  return (
    <div className='CartMessage__Content'>
      <Card sectioned
        title={title}
      >
        <Card.Section>
          <FormLayout>
            <FormLayout.Group condensed>
              <Checkbox
                checked={showMessage}
                label='Show message'
                onChange={handleShowMessageChange}
              />
              <Checkbox
                checked={showPercent}
                label='Show saving in percent'
                onChange={handleShowPercentChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label='Saved amount message'
                value={savedAmountMessage}
                onChange={handleSavedAmountMessageChange}
                placeholder='Saved'
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </div>
  )
}

CartMessage.propTypes = {
  title: PropTypes.string.isRequired,
  showMessage: PropTypes.bool.isRequired,
  showPercent: PropTypes.bool.isRequired,
  savedAmountMessage: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
}
