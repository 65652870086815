import React from 'react'

export const PriceTable = () => {
  return (
    <div className='ProductPageTables'>
      <h4>Table Header</h4>
      <div className='ProductPageTables__Description mamuta-smarty-camp-descr'>
        <p>
          Campaign Description (may be a link)
        </p>
      </div>
      <div className='mamuta-smarty-camp-container'>
        <div className='mamuta-smarty-camp-row'>
          <div className='mamuta-smarty-camp-column'>
            <span>Min</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>Max</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>Discounts header</span>
          </div>
        </div>

        <div className='mamuta-smarty-camp-row'>
          <div className='mamuta-smarty-camp-column'>
            <span>$20.00</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>$29.99</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>$15.00</span>
          </div>
        </div>

        <div className='mamuta-smarty-camp-row mamuta-smarty-camp-some'>
          <div className='mamuta-smarty-camp-column'>
            <span/>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>Hidden breaks warning</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span/>
          </div>
        </div>

        <div className='mamuta-smarty-camp-row'>
          <div className='mamuta-smarty-camp-column'>
            <span>$50.00</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>+</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>$12.50</span>
          </div>
        </div>

      </div>
    </div>
  )
}
