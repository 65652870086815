import React from 'react'
// import { Route, withRouter } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import { Moments } from '@/components/utilities'

/**
 * Single Tier (a part of the Campaign)
 */
export const ActiveDatesTitle = (props) => {
  const { isActive, startDate, endDate, timeZone, title } = props
  return (
    <div className={'ActiveDatesTitle'}>
      <span>{title}</span>
      <div className={'ActiveDatesTitle__Badge'}>
        {
          Moments.getBadge(isActive, _.isNull(startDate) ? null : startDate.format(Moments.DATE_TIME_FORMAT), _.isNull(endDate) ? null : endDate.format(Moments.DATE_TIME_FORMAT), timeZone)
        }
      </div>
    </div>
  )
}

ActiveDatesTitle.propTypes = {
  isActive: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  timeZone: PropTypes.string,
  title: PropTypes.string,
}
