export default class Numbers {
  /**
   * Format is like 'left {{ amount }} right'
   * Returns [left, right]
   * @param format
   */
  static parseMoneyFormat (format) {
    const clean = format.replace(/<[^>]+>/g, '') // strip off any HTML tags
    const parts = _.words(clean, /[^\s{}]+/g) // _.split(format, RegExp('\\s+'))
    // pricelogger('parseMoneyFormat')
    // pricelogger(parts)
    if (parts.length < 2) {
      return ['', ''] // e.g. "{amount}"
    }
    if (parts.length >= 3) {
      return [parts[0], parts[2]] // e.g. "$ {amount} USD"
    }
    const idx0 = parts[0].indexOf('amount')
    const idx1 = parts[1].indexOf('amount')
    if (idx0 >= 0 && idx1 < 0) {
      return ['', parts[1]] // e.g. "{amount} USD"
    } else if (idx1 >= 0 && idx0 < 0) {
      return [parts[0], ''] // e.g. "$ {amount}"
    }
    return ['', '']
  }

  /**
   * Validates decimal numbers
   * @param value
   * @param isMoney
   */
  static validate (value, isMoney) {
    let number = /^\d+$/
    let errMessage = 'Only digits are allowed'
    if (isMoney) {
      number = /^\d+(\.\d{0,2})?$/
      errMessage = 'Only digits and one (optional) decimal point are allowed. Up to 2 digits after the point.'
    }
    return number.test(value) ? '' : errMessage
  }

  /**
   * Tidy view of fixed point value
   * @param value
   * @returns {string}
   */
  static normalizeDecimal (value) {
    const first = value.charAt(0)
    const last = value.charAt(value.length - 1)
    return first === '.'
      ? '0' + value
      : (last === '.' ? value + '00' : value)
  }

  /**
   * Convert to hex string with at least 2 symbols
   * @param value
   * @returns {string}
   */
  static numToHexString (value) {
    return (value > 15 ? value.toString(16) : '0' + value.toString(16)).toUpperCase()
  }
}
