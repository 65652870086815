import React, { useReducer } from 'react'
import * as PropTypes from 'prop-types'
import { TextField, Button, Tooltip } from '@shopify/polaris'
import { CirclePlusMinor, DeleteMinor } from '@shopify/polaris-icons'

import { Numbers } from '@/components/utilities'

const initialState = {
  prerequisiteQuantity: 0,
  value: 0,
  isDeleted: false,
  moneyFormat: '{{amount}}',
  prerequisiteError: '',
  discountError: '',
  prefix: '',
  infix: '',
  suffix: '',
}

const initState = (values) => {
  return { ...initialState, ...values }
}

const SET_STATE_ACTION = 'setAll'
const SET_PREREQUISITE_QUANTITY_ACTION = 'prerequisiteQuantity'
const SET_VALUE_ACTION = 'value'
const SET_DELETED_ACTION = 'isDeleted'
const SET_MONEY_FORMAT_ACTION = 'moneyFormat'
const SET_PREREQUISITE_ERROR_ACTION = 'prerequisiteError'
const SET_DISCOUNT_ERROR_ACTION = 'discountError'
const SET_PREFIX_ACTION = 'prefix'
const SET_INFIX_ACTION = 'infix'
const SET_SUFFIX_ACTION = 'suffix'
const RESET_STATE_ACTION = 'reset'

const reducer = (state, action) => {
  switch (action.type) {
    case SET_STATE_ACTION:
      return {
        ...state,
        ...action.payload
      }
    case SET_PREREQUISITE_QUANTITY_ACTION:
    case SET_VALUE_ACTION:
    case SET_DELETED_ACTION:
    case SET_MONEY_FORMAT_ACTION:
    case SET_PREREQUISITE_ERROR_ACTION:
    case SET_DISCOUNT_ERROR_ACTION:
    case SET_PREFIX_ACTION:
    case SET_INFIX_ACTION:
    case SET_SUFFIX_ACTION:
      return {
        ...state,
        [action.type]: action.payload
      }
    case RESET_STATE_ACTION:
      return initState(action.payload)
    default:
      return state
  }
}

/**
 * Single Tier (a part of the Campaign)
 */
export const TierItem = (props) => {
  const tmpState = {
    prerequisiteQuantity: props.prerequisiteQuantity,
    value: props.value,
    isDeleted: false,
    moneyFormat: Numbers.parseMoneyFormat(props.moneyFormat),
    prerequisiteError: '',
    discountError: '',
    prefix: props.prefix,
    infix: props.infix,
    suffix: props.suffix,
  }
  const [state, dispatch] = useReducer(reducer, tmpState, initState)

  const { id, prerequisiteCount, discountType, prefix, infix, suffix, index, lastOne, isPrice, onUpdate } = props
  const { prerequisiteQuantity, value, moneyFormat, prerequisiteError, discountError } = state

  const changePrefix = (prefix) => {
    pricelogger('TierItem changePrefix')
    pricelogger(prefix)
    dispatch({type: SET_PREFIX_ACTION, payload: prefix})
    onUpdate({
      from: id,
      update: 'prefix',
      value: prefix,
      deleted: false,
      inserted: false,
    })
  }

  const changeInfix = (infix) => {
    dispatch({type: SET_INFIX_ACTION, payload: infix})
    onUpdate({
      from: id,
      update: 'infix',
      value: infix,
      deleted: false,
      inserted: false,
    })
  }

  const changeSuffix = (suffix) => {
    dispatch({type: SET_SUFFIX_ACTION, payload: suffix})
    onUpdate({
      from: id,
      update: 'suffix',
      value: suffix,
      deleted: false,
      inserted: false,
    })
  }

  /**
   * Set prerequisite
   * @param prerequisiteQuantity
   */
  const changePrerequisiteQuantity = (prerequisiteQuantity) => {
    const errMessage = Numbers.validate(prerequisiteQuantity, prerequisiteCount === 'amount')
    if (_.isEmpty(errMessage)) {
      dispatch({type: SET_STATE_ACTION, payload: {prerequisiteQuantity, prerequisiteError: ''}})
      onUpdate({
        from: id,
        update: 'prerequisiteQuantity',
        value: prerequisiteQuantity,
        deleted: false,
        inserted: false,
      })
    } else {
      dispatch({type: SET_PREREQUISITE_ERROR_ACTION, payload: errMessage})
    }
  }

  /**
   * Set discount value
   * @param value
   */
  const changeValue = (value) => {
    const errMessage = Numbers.validate(value, true)
    if (_.isEmpty(errMessage)) {
      dispatch({type: SET_STATE_ACTION, payload: {value, discountError: ''}})
      onUpdate({
        from: id,
        update: 'value',
        value: value,
        deleted: false,
        inserted: false,
      })
    } else {
      dispatch({type: SET_DISCOUNT_ERROR_ACTION, payload: errMessage})
    }
  }

  /**
   * Notify parent on the delete request
   */
  const deleteTier = () => {
    pricelogger('deleteTier')
    dispatch({type: SET_DELETED_ACTION, payload: true})
    onUpdate({
      from: id,
      update: 'delete',
      value: '',
      deleted: true,
      inserted: false,
    })
  }

  /**
   * Notify parent on the new tier request
   */
  const cloneTier = () => {
    pricelogger('cloneTier')
    bulkInsertTiers({
      ascending: true,
      breakStart: parseFloat(prerequisiteQuantity) + 1 + '',
      breakStep: 5 + '',
      discountStep: 5 + '',
      discountStart: parseFloat(value) + 1 + '',
      tiersNumber: 1 + '',
    })
  }

  /**
   * Notify parent on the bulk insert request
   */
  const bulkInsertTiers = (bulk) => {
    pricelogger('TierItem bulkInsertTiers')
    pricelogger(bulk)
    onUpdate({
      from: id,
      update: 'bulk',
      value: bulk,
      deleted: false,
      inserted: true,
    })
  }

  return (
      <div className='TierItem__Wrapper'>
        <div className='TierItem__TextField TierItem__PrefixValue'>
          <TextField
            label={'Prefix'}
            value={prefix}
            onChange={changePrefix}
          />
        </div>
        <div className='TierItem__TextField TierItem__BreakValue'>
          <TextField
            label={'Break at ' + prerequisiteCount}
            value={prerequisiteQuantity}
            type={prerequisiteCount === 'amount' ? 'currency' : 'number'}
            prefix={prerequisiteCount === 'amount' ? moneyFormat[0] : ''}
            suffix={prerequisiteCount === 'amount' ? moneyFormat[1] : ''}
            min={0}
            error={prerequisiteError}
            onChange={changePrerequisiteQuantity}
          />
        </div>
        <div className='TierItem__TextField TierItem__InfixValue'>
          <TextField
            label={'Infix'}
            value={infix}
            onChange={changeInfix}
          />
        </div>
        <div className='TierItem__TextField TierItem__DiscountValue'>
          <TextField
            label={isPrice ? 'Price per item' : 'Discount (in ' + discountType + ')'}
            value={value}
            suffix={discountType === 'percent' ? '%' : moneyFormat[1]}
            prefix={discountType === 'amount' ? moneyFormat[0] : ''}
            error={discountError}
            onChange={changeValue}
          />
        </div>
        <div className='TierItem__TextField TierItem__SuffixValue'>
          <TextField
            label={'Suffix'}
            value={suffix}
            onChange={changeSuffix}
            connectedRight={
              <div className='TierItem__SuffixValue--connection'>
                <div className='TierItem__CloneTier'>
                  <Tooltip content='Add tier'>
                    <Button
                      primary
                      disabled={!lastOne}
                      icon={CirclePlusMinor}
                      onClick={cloneTier}
                    />
                  </Tooltip>
                </div>
                <div className='TierItem__DeleteTier'>
                  <Tooltip content={index === 0 ? 'First tier cannot be deleted' : 'Delete this tier'}>
                    <Button
                      destructive
                      disabled={index === 0}
                      icon={DeleteMinor}
                      onClick={deleteTier}
                    />
                  </Tooltip>
                </div>
              </div>
            }
          />
        </div>
      </div>
    )
}

TierItem.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  lastOne: PropTypes.bool.isRequired,
  isPrice: PropTypes.bool.isRequired,
  prerequisiteCount: PropTypes.string.isRequired,
  prerequisiteQuantity: PropTypes.string.isRequired,
  discountType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  moneyFormat: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  infix: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
}
