import { BasicTable } from '@/components/editor/tables/BasicTable'
import { RangeTable } from '@/components/editor/tables/RangeTable'
import { RangeAltTable } from '@/components/editor/tables/RangeAltTable'
import { PriceAltTable } from '@/components/editor/tables/PriceAltTable'
import { PriceTable } from '@/components/editor/tables/PriceTable'
import { PriceBasicTable } from '@/components/editor/tables/PriceBasicTable'
import { BasicPlusTable } from '@/components/editor/tables/BasicPlusTable'
import { RangePlusTable } from '@/components/editor/tables/RangePlusTable'
import { RangeAltPlusTable } from '@/components/editor/tables/RangeAltPlusTable'

const BASIC_TABLE = 'basic'
const BASIC_PLUS_TABLE = 'basicplus'
const RANGE_TABLE = 'range'
const RANGE_PLUS_TABLE = 'rangeplus'
const RANGE_ALT_TABLE = 'rangealt'
const RANGE_PLUS_ALT_TABLE = 'rangealtplus'
const PRICE_BASIC_TABLE = 'pricebasic'
const PRICE_TABLE = 'price'
const PRICE_ALT_TABLE = 'pricealt'

export const productPageTablesSelectOptions = [
  { label: 'Basic', value: BASIC_TABLE, disabled: false },
  { label: 'Basic Plus', value: BASIC_PLUS_TABLE, disabled: false },
  { label: 'Grid Range', value: RANGE_TABLE, disabled: false },
  { label: 'Grid Range Plus', value: RANGE_PLUS_TABLE, disabled: false },
  { label: 'Grid Range Alternative', value: RANGE_ALT_TABLE, disabled: false },
  { label: 'Grid Range Alternative Plus', value: RANGE_PLUS_ALT_TABLE, disabled: false },
  { label: 'Price Per Item Basic', value: PRICE_BASIC_TABLE, disabled: false },
  { label: 'Price Per Item Range', value: PRICE_TABLE, disabled: false },
  { label: 'Price Per Item Alternative', value: PRICE_ALT_TABLE, disabled: false },
]

export const productPageTables = {
  [BASIC_TABLE]: BasicTable,
  [BASIC_PLUS_TABLE]: BasicPlusTable,
  [RANGE_TABLE]: RangeTable,
  [RANGE_PLUS_TABLE]: RangePlusTable,
  [RANGE_ALT_TABLE]: RangeAltTable,
  [RANGE_PLUS_ALT_TABLE]: RangeAltPlusTable,
  [PRICE_BASIC_TABLE]: PriceBasicTable,
  [PRICE_TABLE]: PriceTable,
  [PRICE_ALT_TABLE]: PriceAltTable,
}
