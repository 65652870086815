import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'
import { Context } from '@shopify/app-bridge-react'
import { Redirect, ResourcePicker } from '@shopify/app-bridge/actions'
import { Heading, Button, Collapsible, ResourceList } from '@shopify/polaris'

import {
  CampaignContext,
  SET_CAMPAIGN_ACTION,
  ShopifyEntityListItem,
  TOGGLE_SHOW_ITEMS_ACTION
} from '@/components/editor'
import { CampaignTypeSelector, CAMPAIGN_FOR_ORDER, CAMPAIGN_FOR_PRODUCTS, CAMPAIGN_FOR_COLLECTIONS } from '@/components/editor/sections/CampaignTypeSelector'

export const CampaignType = (props) => {

  const appBridge = useContext(Context)
  const campaignContext = useContext(CampaignContext)
  const { campaign, dispatch } = campaignContext

  const { showItemsList } = props

  const handleShowItemsSelector = () => {
    pricelogger('handleShowItemsSelector')
    const picker = ResourcePicker.create(appBridge, {
      resourceType: campaign.appliesTo === CAMPAIGN_FOR_PRODUCTS ? ResourcePicker.ResourceType.Product : ResourcePicker.ResourceType.Collection,
      options: {
        selectMultiple: true,
        showHidden: false,
      },
    })

    picker.subscribe(ResourcePicker.Action.SELECT, ({ selection }) => {
      updateShopifyItems(selection)
      picker.unsubscribe()
    })

    picker.subscribe(ResourcePicker.Action.CANCEL, () => {
      picker.unsubscribe()
    })

    picker.dispatch(ResourcePicker.Action.OPEN)
  }

  const handleShowItemsList = () => {
    dispatch({ type: TOGGLE_SHOW_ITEMS_ACTION, payload: !showItemsList })
  }

  const handleItemDeleteAction = (event) => {
    event.stopPropagation()
    const itemId = event.currentTarget.id
    pricelogger('Delete shopify item ' + itemId)
    const clonedCampaign = _.cloneDeep(campaign)
    if (clonedCampaign.appliesTo === CAMPAIGN_FOR_PRODUCTS) {
      clonedCampaign.products = clonedCampaign.products.filter(item => item.shopifyId + '' !== itemId + '')
    } else {
      clonedCampaign.collections = clonedCampaign.collections.filter(item => item.shopifyId + '' !== itemId + '')
    }
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  const handleItemAction = (id) => {
    pricelogger('Click on shopify item ' + id)
    const shopifyId = (id.split(':'))[0]
    const redirect = Redirect.create(appBridge)
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: `/${campaign.appliesTo}/${shopifyId}`,
      newContext: true,
    })
  }

  const handleVariantDeleteAction = (event) => {
    event.stopPropagation()
    const itemId = event.currentTarget.id
    const productId = event.currentTarget.attributes['aria-controls'].value
    pricelogger(`Delete variant ${itemId} of product ${productId}`)
    const clonedCampaign = _.cloneDeep(campaign)
    const product = clonedCampaign.products.find(product => (product.shopifyId + '' === productId + '') || (product.id + '' === productId + ''))
    if (_.isUndefined(product)) {
      pricelogger('Delete variant product not found')
      return
    }
    product.variants = product.variants.filter(variant => variant.shopifyId + '' !== itemId + '')
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  /**
   * Picks the items from the ResourcePicker
   * @param selection
   */
  const updateShopifyItems = (selection) => {
    const updatedCampaign = campaign.appliesTo === CAMPAIGN_FOR_COLLECTIONS
      ? updateEntitledCollections(selection)
      : updateEntitledProducts(selection)
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: updatedCampaign })
  }

  const extractId = graphId => parseInt(graphId.split('/').pop(), 10)

  /**
   * Update collections
   * @param selection
   * @returns {*}
   */
  const updateEntitledCollections = (selection) => {
    const clonedCampaign = _.cloneDeep(campaign)
    if (_.isUndefined(selection) || _.isEmpty(selection)) {
      return clonedCampaign
    }
    pricelogger(selection)

    selection.forEach(item => {
      const shopifyId = extractId(item.id)
      const enlistedCollectionIdx = clonedCampaign.collections.findIndex(collection => collection.shopifyId === shopifyId)
      const justAdded = enlistedCollectionIdx < 0
      const collection = {
        id: justAdded ? (_.now() + _.random(1000)) : clonedCampaign.collections[enlistedCollectionIdx].id,
        shopifyId,
        title: item.title,
        justAdded,
      }
      if (justAdded) {
        clonedCampaign.collections.push(_.cloneDeep(collection))
      } else {
        clonedCampaign.collections[enlistedCollectionIdx] = _.cloneDeep(collection)
      }
    })

    pricelogger('After collections')
    pricelogger(clonedCampaign.collections)
    return clonedCampaign
  }

  /**
   * Update products and variants
   * @param selection
   * @returns {*}
   */
  const updateEntitledProducts = (selection) => {
    const clonedCampaign = _.cloneDeep(campaign)
    if (_.isUndefined(selection) || _.isEmpty(selection)) {
      return clonedCampaign
    }
    pricelogger(selection)

    selection.forEach(item => {
      const shopifyId = extractId(item.id)
      const enlistedProductIdx = clonedCampaign.products.findIndex(product => product.shopifyId === shopifyId)
      const justAdded = enlistedProductIdx < 0

      const product = {
        id: justAdded ? (_.now() + _.random(1000)) : clonedCampaign.products[enlistedProductIdx].id,
        shopifyId,
        title: item.title,
        justAdded,
        variants: justAdded ? [] : clonedCampaign.products[enlistedProductIdx].variants,
      }

      item.variants.forEach(variant => {
        const shopifyId = extractId(variant.id)
        const variantIdx = product.variants.findIndex(v => v.shopifyId === shopifyId)
        const justAdded = variantIdx < 0
        const updatedVariant = {
          id: justAdded ? (_.now() + _.random(1000)) : product.variants[variantIdx].id,
          productId: product.id,
          shopifyId,
          title: !(typeof variant.title === 'undefined' || _.isEmpty(variant.title)) ? variant.title : product.title,
          justAdded,
          isOnlyVariant: item.hasOnlyDefaultVariant,
        }
        if (justAdded) {
          product.variants.push(_.cloneDeep(updatedVariant))
        } else {
          product.variants[variantIdx] = _.cloneDeep(updatedVariant)
        }
      })

      if (justAdded) {
        clonedCampaign.products.push(_.cloneDeep(product))
      } else {
        clonedCampaign.products[enlistedProductIdx] = _.cloneDeep(product)
      }
    })

    pricelogger('After')
    pricelogger(clonedCampaign.products)
    return clonedCampaign
  }

  return (
    <div className='CampaignType'>
      <Heading>Campaign Type</Heading>
      <CampaignTypeSelector/>
      <div className='CampaignType__ItemSelector'>
        <Button primary fullWidth
                disabled={_.isUndefined(campaign.appliesTo) || (campaign.appliesTo === CAMPAIGN_FOR_ORDER)}
                onClick={handleShowItemsSelector}
        >
          {(campaign.appliesTo === CAMPAIGN_FOR_ORDER ? 'Not Applicable' : 'Select ' + campaign.appliesTo)}
        </Button>
      </div>
      <Collapsible id='shopifyItems' open={
        (campaign.appliesTo === CAMPAIGN_FOR_COLLECTIONS && !_.isEmpty(campaign.collections)) ||
        (campaign.appliesTo === CAMPAIGN_FOR_PRODUCTS && !_.isEmpty(campaign.products))
      }>
        <div className={'TierPriceEditor__ShopifyList' + (showItemsList ? '--active' : '')}>
          <ResourceList
            alternateTool={
              <Button onClick={handleShowItemsList}>
                {(showItemsList ? 'Hide ' : 'Show ') + (campaign.appliesTo && campaign.appliesTo !== CAMPAIGN_FOR_ORDER ? campaign.appliesTo : '') + ' List'}
              </Button>
            }
            items={
              campaign.appliesTo === CAMPAIGN_FOR_COLLECTIONS
                ? campaign.collections
                : campaign.products
            }
            renderItem={
              (item) => {
                return (
                  showItemsList
                    ? <ShopifyEntityListItem key={item.id} {...item} customActions={[
                      {
                        name: 'remove',
                        onAction: handleItemDeleteAction,
                      },
                    ]}
                                             onClick={(id) => handleItemAction(id)}
                                             onVariantAction={
                                               campaign.appliesTo === CAMPAIGN_FOR_COLLECTIONS
                                                 ? () => {
                                                 }
                                                 : handleVariantDeleteAction
                                             }
                    />
                    : null
                )
              }
            }
          />
        </div>
      </Collapsible>
    </div>
  )
}

CampaignType.propTypes = {
  showItemsList: PropTypes.bool,
}
