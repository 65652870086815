import React from 'react'

export const RangeTable = () => {
  return (
    <div className='ProductPageTables'>
      <h4>Table Header</h4>
      <div className='ProductPageTables__Description mamuta-smarty-camp-descr'>
        <p>
          Campaign Description (may be a link)
        </p>
      </div>
      <div className='ProductPageTables__OrderWarning mamuta-smarty-camp-warning'>
        <em>Entire Order Warning</em>
      </div>
      <ul>
        <li className='mamuta-smarty-camp-breaks'>
          <div className='mamuta-smarty-camp-breaks'>
            <div>
              <span>&nbsp; &nbsp; &nbsp; &nbsp; Min</span>
            </div>
            <div>
              <span>&nbsp; &nbsp; &nbsp; Max</span>
            </div>
            <div>
              Discounts header
            </div>
          </div>
        </li>
        <li>
          <div className='mamuta-smarty-camp-breaks'>
            <div>
              <span className='mamuta-smarty-camp-breaks-value-bump'>2</span>
            </div>
            <div>
              <span className='mamuta-smarty-camp-breaks-value-bump'>4</span>
            </div>
            <div>
              <span className='mamuta-smarty-camp-hidden'>0</span>
              <span className='mamuta-smarty-camp-breaks-value-bump'>10</span>
              <span>%</span>
            </div>
          </div>
        </li>
        <li>
          <div className='mamuta-smarty-camp-breaks'>
            <div>
              <span className='mamuta-smarty-camp-breaks-value-bump'>5</span>
            </div>
            <div>
              <span className='mamuta-smarty-camp-breaks-value-bump'>7</span>
            </div>
            <div>
              <span className='mamuta-smarty-camp-hidden'>0</span>
              <span className='mamuta-smarty-camp-breaks-value-bump'>12</span>
              <span>%</span>
            </div>
          </div>
        </li>
        <li>
          <div className='mamuta-smarty-camp-hidden-warning'>
            <em>Hidden breaks warning</em>
          </div>
        </li>
        <li>
          <div className='mamuta-smarty-camp-breaks'>
            <div>
              <span className='mamuta-smarty-camp-breaks-value-bump'>12</span>
            </div>
            <div>
              <span className='mamuta-smarty-camp-breaks-value-bump'>...</span>
            </div>
            <div>
              <span className='mamuta-smarty-camp-hidden'>0</span>
              <span className='mamuta-smarty-camp-breaks-value-bump'>20</span>
              <span>%</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}
