import React, { useState, useEffect, useContext, useRef } from 'react'
import { Card, Button, Popover, ActionList, TextContainer, Banner, Link, List, Heading } from '@shopify/polaris'
import * as PropTypes from 'prop-types'

import { TopTierInfo, StatInfo } from '@/components/dashboard'
import { Strings, Moments } from '@/components/utilities'
import { RootContext } from '@/components'
import { BackendAPI } from '@/api'

export const AppDashboard = (props) => {
  const { shopUrl, location } = props
  const [performance, setPerformance] = useState([])
  const [statistics, setStatistics] = useState({})
  const [popoverActive, setPopoverActive] = useState(false)
  const rootContext = useContext(RootContext)

  // useEffect(() => {
  //   onMount(location.pathname)
  // }, [location, onMount])

  useEffect(() => {
    fetchStatistics()
  }, [])

  const togglePopover = () => setPopoverActive(popoverActive => !popoverActive)

  const popActivator = (
    <Button onClick={togglePopover}>Reset statistics</Button>
  )

  const resetCounters = async (counter) => {
    try {
      await BackendAPI.resetCounters(Strings.getHostName(shopUrl), { counter })
      await fetchStatistics()
      setPopoverActive(popoverActive => !popoverActive)
      await rootContext.showNotification('Statistic counters reset')
    } catch (error) {
      pricelogger('resetCounters fault')
      pricelogger(error)
      await rootContext.showNotification('Cannot reset statistic counters', true)
      bugsnagClient.notify(
        error,
        {
          metaData: {
            component: {
              class: 'AppDashboard',
              function: 'BackendAPI.resetCounters',
            }
          }
        }
      )
    }
  }

  const fetchStatistics = async () => {
    try {
      const response = await BackendAPI.getStatistics(Strings.getHostName(shopUrl))
      const performanceData = [
        {
          title: 'product page',
          data: response.data.product.map(record => {
            const timeZone = Moments.getLocalTZ(record.tz)
            return {
              id: record.id,
              title: record.title,
              count: record.product_counter,
              status: record.status,
              startsAt: Moments.makeLocalTime(record.startsAt, timeZone, false),
              endsAt: Moments.makeLocalTime(record.endsAt, timeZone, true),
              tz: timeZone,
            }
          })
        },
        {
          title: 'cart page',
          data: response.data.cart.map(record => {
            const timeZone = Moments.getLocalTZ(record.tz)
            return {
              id: record.id,
              title: record.title,
              count: record.cart_counter,
              status: record.status,
              startsAt: Moments.makeLocalTime(record.startsAt, timeZone, false),
              endsAt: Moments.makeLocalTime(record.endsAt, timeZone, true),
              tz: timeZone,
            }
          })
        },
        {
          title: 'checkouts',
          data: response.data.checkout.map(record => {
            const timeZone = Moments.getLocalTZ(record.tz)
            return {
              id: record.id,
              title: record.title,
              count: record.checkout_counter,
              status: record.status,
              startsAt: Moments.makeLocalTime(record.startsAt, timeZone, false),
              endsAt: Moments.makeLocalTime(record.endsAt, timeZone, true),
              tz: timeZone,
            }
          })
        },
      ]
      setPerformance(performanceData)
      setStatistics(response.data.tierPricesStat)
    } catch (error) {
      pricelogger('getStatistics fault')
      pricelogger(error)
      bugsnagClient.notify(
        error,
        {
          metaData: {
            component: {
              class: 'AppDashboard',
              function: 'BackendAPI.getStatistics',
            }
          }
        }
      )
    }
  }

  return (
    <Card sectioned title='Dashboard'
          actions={
            [
              {
                content: 'Refresh statistics',
                onAction: fetchStatistics,
              },
            ]
          }
    >
      <div className='Dashboard__PopoverActions'>
        <Popover
          active={popoverActive}
          activator={popActivator}
          onClose={togglePopover}
        >
          <ActionList
            items={
              [
                {
                  content: '',
                  badge: { content: 'Reset expired', status: 'warning' },
                  onAction: () => {
                    pricelogger('Reset expired')
                    resetCounters('expired')
                  },
                },
                {
                  content: '',
                  badge: { content: 'Reset inactive' },
                  onAction: () => {
                    pricelogger('Reset inactive')
                    resetCounters('disabled')
                  },
                },
                {
                  content: '',
                  badge: { content: 'Reset active', status: 'success' },
                  onAction: () => {
                    pricelogger('Reset running')
                    resetCounters('running')
                  },
                },
                {
                  destructive: true,
                  content: 'Reset All',
                  onAction: () => {
                    pricelogger('Reset All')
                    resetCounters('all')
                  },
                },
              ]
            }
          />
        </Popover>
      </div>
      <Card.Section title='Campaigns Performance'>
        <div className='Dashboard__Performance'>
          {
            performance.map((table, idx) => {
              return <TopTierInfo {...props} title={table.title} data={table.data} total={statistics.total}
                                  key={idx}/>
            })
          }
        </div>
      </Card.Section>
      <Card.Section title='Campaigns Statistics'>
        <StatInfo {...props} {...statistics}/>
      </Card.Section>
    </Card>
  )
}

AppDashboard.propTypes = {
  name: PropTypes.string,
  version: PropTypes.string,
  shopUrl: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
