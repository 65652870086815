import React, { useContext } from 'react'
import { Context } from '@shopify/app-bridge-react'
import { getSessionToken } from '@shopify/app-bridge-utils';
import { VueWrapper } from 'vuera'
import CampaignWizard from '@/vue/CampaignWizard.vue'
import { ResourcePicker } from '@shopify/app-bridge/actions'

export const ReactCampaignWizard = (props) => {
  const appBridge = useContext(Context)
  console.log(props)
  // props.appBridge = appBridge
  let myProps={...props}
  window.axios.interceptors.request.use((config) => {
          return getSessionToken(appBridge)
              .then((token) => {
                  config.headers['Authorization'] = `Bearer ${token}`;
                  return config;
              });
      }
  );
  myProps.appBridge = appBridge
  if (typeof props.match.params.id !== 'undefined') myProps.id = props.match.params.id
  myProps.productPicker = ResourcePicker.create(appBridge, {
    resourceType: ResourcePicker.ResourceType.Product,
    options: {
      selectMultiple: true,
      showHidden: false,
    },
  })

  myProps.collectionPicker = ResourcePicker.create(appBridge, {
    resourceType: ResourcePicker.ResourceType.Collection,
    options: {
      selectMultiple: true,
      showHidden: false,
    },
  })




  console.log(props)
  return (
    <div>
      <VueWrapper
        component={CampaignWizard}
        message='Hello from Vue!'
        {...myProps}
      />
    </div>
  )
}
