<template>
<div>
  <div v-if="settings.productTableHandle==='basic'">
    <div class='ProductPageTables'>
      <h4>{{ settings.productTableHeader }}</h4>
      <div class='ProductPageTables__Description mamuta-smarty-camp-descr'>
        <p>
          {{ description }}
        </p>
      </div>
      <div class='ProductPageTables__OrderWarning mamuta-smarty-camp-warning' v-if="discountType=='order'">
        <em>{{settings.productTableEntireOrderWaring}}</em>
      </div>
      <ul>
        <li class='mamuta-smarty-camp-breaks'>
          <div class='mamuta-smarty-camp-breaks'>
            <div>{{  settings.productTableBreaksHeader  }}</div>
            <div>{{  settings.productTableDiscountsHeader }}</div>
          </div>
        </li>
        <li>
          <div class='mamuta-smarty-camp-breaks'  v-for="(tier,index) in tiers.slice(0,settings.productTableMaxBreaksNumber)" v-if="tier.prerequisiteQuantity > 0">
            <div class='mamuta-smarty-camp-breaks-prefix'>
              <span>{{  tier.prefix }}</span>
            </div>
            <div>
              <span class='mamuta-smarty-camp-hidden'>00</span>
              <span class='mamuta-smarty-camp-hidden'>0</span>
              <span class='mamuta-smarty-camp-breaks-value-bump'>{{ tier.prerequisiteQuantity }}+</span>
            </div>
            <div>
              <span>{{  tier.infix }}</span>
            </div>
            <div>
              <span class='mamuta-smarty-camp-hidden'>00</span>
              <span class='mamuta-smarty-camp-hidden'>0</span>
              <span v-if="discountType === 'amount'">$</span>
              <span class='mamuta-smarty-camp-breaks-value-bump'>{{  tier.value }}</span>
              <span v-if="discountType === 'percent'">%</span>
            </div>
            <div class='mamuta-smarty-camp-breaks-suffix'>
              <span>{{  tier.suffix }}</span>
            </div>
          </div>
        </li>
        <li v-if="tiers.length > settings.productTableMaxBreaksNumber">
          <div class='mamuta-smarty-camp-hidden-warning'>
            <em>{{  settings.productTableHiddenBreaksWarning.replace('%s',this.tiers.length - (this.settings.productTableMaxBreaksNumber +1)) }}</em>
          </div>
        </li>
        <li v-if="tiers.length > settings.productTableMaxBreaksNumber">
          <div class='mamuta-smarty-camp-breaks'>
            <div class='mamuta-smarty-camp-breaks-prefix'>
              <span>{{  lastTier.prefix }}</span>
            </div>
            <div>
              <span class='mamuta-smarty-camp-hidden'>0</span>
              <span class='mamuta-smarty-camp-breaks-value-bump'>{{ lastTier.prerequisiteQuantity }}+</span>
            </div>
            <div>
              <span>{{  lastTier.infix }}</span>
            </div>
            <div>
              <span class='mamuta-smarty-camp-hidden'>0</span>
              <span v-if="discountType === 'amount'">$</span>

              <span class='mamuta-smarty-camp-breaks-value-bump'>{{ lastTier.value }}</span>
              <span v-if="discountType === 'percent'">%</span>

            </div>
            <div class='mamuta-smarty-camp-breaks-suffix'>
              <span>{{  lastTier.suffix }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class='ProductPageTables' v-if="settings.productTableHandle==='range'">
    <h4>{{  settings.productTableHeader }}</h4>
    <div class='ProductPageTables__Description mamuta-smarty-camp-descr'>
      <p>
        {{  description }}
      </p>
    </div>
    <div class='ProductPageTables__OrderWarning mamuta-smarty-camp-warning' v-if="discountType==='order'">
      <em>{{  settings.productTableEntireOrderWaring }}</em>
    </div>
    <ul>
      <li class='mamuta-smarty-camp-breaks'>
        <div class='mamuta-smarty-camp-breaks'>
          <div>
            <span>&nbsp; &nbsp; &nbsp; &nbsp; Min</span>
          </div>
          <div>
            <span>&nbsp; &nbsp; &nbsp; Max</span>
          </div>
          <div>
            {{  settings.productTableDiscountsHeader }}
          </div>
        </div>
      </li>
      <li v-for="(tier,index) in tiers">
        <div class='mamuta-smarty-camp-breaks' >
          <div>
            <span class='mamuta-smarty-camp-breaks-value-bump'>{{ tier.prerequisiteQuantity }}</span>
          </div>
          <div>
            <span class='mamuta-smarty-camp-breaks-value-bump' v-if="typeof tiers[index+1] === 'undefined'">...</span>
            <span class="mamuta-smarty-camp-breaks-value-bump" v-else>{{ (tiers[index+1].prerequisiteQuantity -1) }}</span>
          </div>
          <div>
            <span class='mamuta-smarty-camp-hidden'>0</span>
            <span v-if="discountType === 'amount'">$</span>
            <span class='mamuta-smarty-camp-breaks-value-bump'>{{ tier.value }}</span>
            <span v-if="discountType === 'percent'">%</span>

          </div>
        </div>
      </li>
      <li>
        <div class='mamuta-smarty-camp-hidden-warning'>
          <em>{{  settings.productTableHiddenBreaksWarning }}</em>
        </div>
      </li>
      <li>
        <div class='mamuta-smarty-camp-breaks'>
          <div>
            <span class='mamuta-smarty-camp-breaks-value-bump'>12</span>
          </div>
          <div>
            <span class='mamuta-smarty-camp-breaks-value-bump'>...</span>
          </div>
          <div>
            <span class='mamuta-smarty-camp-hidden'>0</span>
            <span class='mamuta-smarty-camp-breaks-value-bump'>20</span>
            <span>%</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div v-if="settings.productTableHandle === 'pricebasic'">
    <div class='ProductPageTables'>
      <h4>{{  settings.productTableHeader }}</h4>
      <div class='ProductPageTables__Description mamuta-smarty-camp-descr'>
        <p>
          {{ description }}
        </p>
      </div>
      <div class='mamuta-smarty-camp-container'>
        <div class='mamuta-smarty-camp-row2'>
          <div class='mamuta-smarty-camp-column'>
            {{ settings.productTableBreaksHeader }}
          </div>
          <div class='mamuta-smarty-camp-column'>
            {{ settings.productTableDiscountsHeader }}
          </div>
        </div>
        <div class='mamuta-smarty-camp-row2' v-for="tier in tiers">
          <div class='mamuta-smarty-camp-column'>
            <span class='mamuta-smarty-camp-prefix'>{{  tier.prefix }}</span>
            <span>{{  tier.prerequisiteQuantity }}</span>
            <span> +</span>
          </div>
          <div class='mamuta-smarty-camp-column'>
            <span class='mamuta-smarty-camp-infix'>{{ tier.infix }}</span>
            <span class='mamuta-smarty-camp-breaks-value-bump'>${{tier.value}}</span>
            <span class='mamuta-smarty-camp-suffix'> {{ tier.suffix }}</span>
          </div>
        </div>
        <div class='mamuta-smarty-camp-row2 mamuta-smarty-camp-some'>
          <div class='mamuta-smarty-camp-column'>
            <span/>
          </div>
          <div class='mamuta-smarty-camp-column'>
            <span>{{  settings.productTableHiddenBreaksWarning }}</span>
          </div>
        </div>
        <div class='mamuta-smarty-camp-row2'>
          <div class='mamuta-smarty-camp-column'>
            <span class='mamuta-smarty-camp-prefix'>Prefix </span>
            <span>200</span>
            <span> +</span>
          </div>
          <div class='mamuta-smarty-camp-column'>
            <span class='mamuta-smarty-camp-infix'>Infix </span>
            <span class='mamuta-smarty-camp-breaks-value-bump'>$15.00</span>
            <span class='mamuta-smarty-camp-suffix'> Suffix</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "CampaignPreview",
props: ['settings','tiers','description', 'discountType'],
  computed: {
    lastTier() {
      var allTiers = this.tiers
      return allTiers.pop();
    }
  }
}
</script>

<style scoped>

</style>
