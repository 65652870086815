import React, { useEffect, useReducer, useContext } from 'react'
import {
  Card,
  ResourceList,
  Pagination,
  ResourceItem,
  Filters,
  ChoiceList
} from '@shopify/polaris'
import * as PropTypes from 'prop-types'

import { TierPriceListItem, TierPriceListFooter } from '@/components'
import { BackendAPI } from '@/api'
import { SET_CAMPAIGN_ACTION } from '@/components/AppRoot'
import { getTierPriceTemplate } from '@/components/utilities'
import { RootContext } from '@/components'

const SORT_BY_TITLE_ASC = 'TITLE_ASC'
const SORT_BY_TITLE_DESC = 'TITLE_DESC'
const SORT_BY_UPDATE_DATE_ASC = 'DATE_UPDATED_ASC'
const SORT_BY_UPDATE_DATE_DESC = 'DATE_UPDATED_DESC'

const FILTER_BY_ACTIVE_STATE_KEY = 'is_active'
const FILTER_BY_APPLIES_TO_KEY = 'applies_to'
const FILTER_BY_PREREQUISITE_KEY = 'prerequisite_count'
const FILTER_BY_DISCOUNT_KEY = 'discount_type'

const FILTER_BY_ACTIVE_STATE_ACTIVE_VALUE = 'yes'
const FILTER_BY_ACTIVE_STATE_INACTIVE_VALUE = 'no'

const FILTER_BY_APPLIES_TO_ORDER_VALUE = 'order'
const FILTER_BY_APPLIES_TO_PRODUCTS_VALUE = 'products'
const FILTER_BY_APPLIES_TO_COLLECTIONS_VALUE = 'collections'

const FILTER_BY_PREREQUISITE_AMOUNT_VALUE = 'amount'
const FILTER_BY_PREREQUISITE_QUANTITY_VALUE = 'quantity'
const FILTER_BY_DISCOUNT_AMOUNT_VALUE = 'amount'
const FILTER_BY_DISCOUNT_PERCENT_VALUE = 'percent'

const sortOptions = [
  { label: 'Title a-Z', value: SORT_BY_TITLE_ASC },
  { label: 'Title Z-a', value: SORT_BY_TITLE_DESC },
  { label: 'Newest update', value: SORT_BY_UPDATE_DATE_DESC },
  { label: 'Oldest update', value: SORT_BY_UPDATE_DATE_ASC },
]

const initialState = {
  rules: [], // All (one page) of the Campaigns
  selectedItems: [], // Selected for a bulk action
  sortValue: SORT_BY_UPDATE_DATE_DESC, // "Sort By" settings
  filtersState: {
    [FILTER_BY_ACTIVE_STATE_KEY]: '',
    [FILTER_BY_APPLIES_TO_KEY]: '',
  },
  searchValue: '', // Current search field value
  isFirstPage: true,
  isLastPage: false,
  pageNumber: 1, // Current page number
  links: {}, // Pagination links
}

const initState = (pageNumber) => {
  return { ...initialState, pageNumber }
}

const SET_STATE_ACTION = 'setAll'
const SET_RULES_ACTION = 'rules'
const SET_SELECTION_ACTION = 'selectedItems'
const SET_SORT_ORDER_ACTION = 'sortValue'
const SET_FILTERS_ACTION = 'appliedFilters'
const SET_SEARCH_ACTION = 'searchValue'
const SET_PAGE_ACTION = 'setPage'
const RESET_STATE_ACTION = 'reset'

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
const reducer = (state, action) => {
  const prevLink = action.payload.links ? action.payload.links.prev : state.links.prev
  const nextLink = action.payload.links ? action.payload.links.next : state.links.next
  switch (action.type) {
    case SET_STATE_ACTION:
      // const newState = _.merge(state, action.payload)
      // console.log('NEW STATE', newState)
      return {
        // ...newState,
        ...state,
        ...action.payload,
        isFirstPage: prevLink === null,
        isLastPage: nextLink === null
      }
    case SET_RULES_ACTION:
      return {
        ...state,
        [action.type]: action.payload,
      }
    case SET_FILTERS_ACTION:
      const clonedFilters = _.cloneDeep(state.filtersState)
      clonedFilters[action.payload.key] = action.payload.value
      return {
        ...state,
        filtersState: clonedFilters,
      }
    case SET_SELECTION_ACTION:
    case SET_SORT_ORDER_ACTION:
    case SET_SEARCH_ACTION:
      return {
        ...state,
        [action.type]: action.payload
      }
    case SET_PAGE_ACTION:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        isFirstPage: action.payload.links.prev === null,
        isLastPage: action.payload.links.next === null,
      }
    case RESET_STATE_ACTION:
      return initState(action.payload)
    default:
      return state
  }
}

const remapFilters = (filtersState) => {
  const filters = []
  for (let [key, value] of Object.entries(filtersState)) {
    filters.push({
      column: key,
      value,
    })
  }
  return filters
}

/**
 * List (ResourceList) of Campaigns.
 * Reads data from the backend, invokes the single Campaign editor
 */
export const AppCampaigns = (props) => {
  const { history, shopSettings } = props
  const [state, dispatch] = useReducer(reducer, 1, initState)
  const rootContext = useContext(RootContext)

  useEffect(() => {
    pricelogger('AppCampaigns fetch State effect')
    const fetchData = async () => {
      const filters = remapFilters(initialState.filtersState)
      const params = {
        page: initialState.pageNumber,
        sort: initialState.sortValue,
        search: initialState.searchValue,
        filter: filters.length > 0 ? filters : ''
      }

      try {
        const response = await BackendAPI.getTierPrices(params)
        pricelogger('AppCampaigns effect fetchRules', response)
        dispatch({
          type: SET_STATE_ACTION,
          payload: { pageNumber: 1, links: response.data.links, rules: response.data.data }
        })
      } catch (error) {
        pricelogger('fetchRules fault')
        pricelogger(error)
        bugsnagClient.notify(
          error,
          {
            metaData: {
              component: {
                class: 'AppCampaigns',
                function: 'useEffect fetchData',
              },
            },
          }
        )
      }
    }
    fetchData()
  }, [])

  const resourceName = {
    singular: 'campaign',
    plural: 'campaigns',
  }

  /**
   * Helper for the data retrieval. Sets the relevant parameters for the BackendAPI.
   * @param page Page number to fetch
   * @param sortValue
   * @param searchValue
   * @param appFilters
   * @returns {Promise<T | never>}
   */
  const fetchRules = async (page = null, sortValue = null, searchValue = null, appFilters = null) => {
    const filt = remapFilters(appFilters ? appFilters : state.filtersState)

    const params = {
      page: _.isNull(page) ? state.pageNumber : page,
      sort: _.isNull(sortValue) ? state.sortValue : sortValue,
      search: _.isNull(searchValue) ? state.searchValue : searchValue,
      filter: filt.length > 0 ? filt : ''
    }

    try {
      const response = await BackendAPI.getTierPrices(params)
      return response.data
    } catch (error) {
      pricelogger('fetchRules fault')
      pricelogger(error)
      bugsnagClient.notify(
        error,
        {
          metaData: {
            component: {
              class: 'AppCampaigns',
              function: 'BackendAPI.getTierPrices',
            },
          },
        }
      )
    }
  }

  /**
   * Helper function. Sets new current data page and fetches the data.
   * Note: the backend implements "simplePaging", so there are no individual page links.
   * @param page The number of page to set
   */
  const setCurrentPage = page => {
    fetchRules(page)
      .then(response => {
        dispatch({ type: SET_STATE_ACTION, payload: { pageNumber: page, links: response.links, rules: response.data } })
      })
  }

  /**
   * Set the current Campaign and route to the single Campaign editor
   * Lifted from TierPriceListItem. "onClick" property there
   * @param id Campaign id
   */
  const handleItemAction = (id) => {
    pricelogger('handleItemAction ' + id)
    rootContext.dispatch({ type: SET_CAMPAIGN_ACTION, payload: state.rules.find(rule => rule.id + '' === id + '') })
    history.push('/edit/' + id)
  }

  /**
   * Page backward. Lifted from Pagination "onPrevious" property there
   */
  const handlePreviousPage = () => {
    setCurrentPage(state.pageNumber - 1)
  }

  /**
   * Page forward. Lifted from Pagination "onNext" property there
   */
  const handleNextPage = () => {
    setCurrentPage(state.pageNumber + 1)
  }

  /**
   * Fetch the found data
   * @param searchValue
   */
  const handleSearchChange = async (searchValue) => { //useCallback(
    try {
      const response = await fetchRules(1, state.sortValue, searchValue)
      dispatch({
        type: SET_STATE_ACTION,
        payload: { searchValue, pageNumber: 1, links: response.links, rules: response.data }
      })
    } catch (error) {
      pricelogger(error)
    }
  }

  /**
   * Fetch the sorted data
   * @param sortValue
   */
  const handleSortChange = (sortValue) => {
    fetchRules(1, sortValue)
      .then(response => {
        dispatch({
          type: SET_STATE_ACTION,
          payload: { sortValue, pageNumber: 1, links: response.links, rules: response.data }
        })
      })
  }

  /**
   * Selection (for the bulk actions) control
   * @param selectedItems
   */
  const handleSelectionChange = (selectedItems) => {
    pricelogger('handleSelectionChange')
    pricelogger(selectedItems)
    dispatch({ type: SET_SELECTION_ACTION, payload: selectedItems })
  }

  /**
   * Delete in bulk handler.
   * @param event
   */
  const handleBulkDelete = (event) => {
    const ids = state.selectedItems
    pricelogger('doBulkDelete')
    BackendAPI.bulkDeleteTierPrice(ids)
      .then(response => {
        setCurrentPage(state.pageNumber > 1 ? state.pageNumber - 1 : 1)
        dispatch({ type: SET_SELECTION_ACTION, payload: [] })
        rootContext.showNotification('Campaigns deleted')
      })
      .catch(error => {
        pricelogger('Cannot bulk delete')
        pricelogger(error)
        bugsnagClient.notify(
          error,
          {
            metaData: {
              component: {
                class: 'AppCampaigns',
                function: 'BackendAPI.bulkDeleteTierPrice',
              },
            },
          }
        )
        rootContext.showNotification('Campaigns bulk delete action fault', true)
      })
  }

  /**
   * Bulk disable worker
   */
  const handleBulkDisable = () => {
    const ids = state.selectedItems
    runBulkChangeActiveState(ids, 'disable')
    rootContext.showNotification('Campaigns deactivated')
  }

  /**
   * Bulk enable worker
   */
  const handleBulkEnable = () => {
    const ids = state.selectedItems
    runBulkChangeActiveState(ids, 'enable')
    rootContext.showNotification('Campaigns activated')
  }

  /**
   * Delete single Campaign
   */
  const handleItemDeleteAction = (event) => {
    pricelogger('handleItemDeleteAction')
    event.stopPropagation()
    const itemId = event.currentTarget.id
    let item = state.rules.find(rule => rule.id + '' === itemId + '')
    if (_.isUndefined(item)) {
      pricelogger('handleItemDeleteAction Undefined')
      return
    }
    BackendAPI.deleteTierPrice(itemId)
      .then(response => {
        const rules = state.rules.filter(item => item.id + '' !== itemId + '')
        dispatch({ type: SET_RULES_ACTION, payload: rules })
        rootContext.showNotification('Campaign deleted')
      })
      .catch(error => {
        pricelogger('Cannot delete')
        pricelogger(error)
        bugsnagClient.notify(
          error,
          {
            metaData: {
              component: {
                class: 'AppCampaigns',
                function: 'BackendAPI.deleteTierPrice',
              },
              item: {
                itemId: itemId,
              }
            },
          }
        )
        rootContext.showNotification('Delete action fault', true)
      })
  }

  /**
   * Disable single Campaign
   *  Lifted from TierPriceListItem as "onAction" property of the custom actions
   * @param event
   */
  const handleItemDisableAction = (event) => {
    event.stopPropagation()
    const itemId = event.currentTarget.id
    runChangeActiveStateAction(itemId, 'disable')
  }

  /**
   *  Enable single Campaign
   *  Lifted from TierPriceListItem as "onAction" property of the custom actions
   * @param event
   */
  const handleItemEnableAction = (event) => {
    event.stopPropagation()
    const itemId = event.currentTarget.id
    runChangeActiveStateAction(itemId, 'enable')
  }

  /**
   * Enable/disable helper function.
   * @param ruleId
   * @param action
   */
  const runChangeActiveStateAction = async (ruleId, action) => {
    try {
      const response = await BackendAPI.updateTierPrice(ruleId, { action })
      const rules = _.cloneDeep(state.rules)
      // that "+ ''" is to convert BOTH ids to the same (string) type and allow comparision
      const updatedRule = rules.find(rule => rule.id + '' === ruleId + '')
      updatedRule.isActive = action === 'enable'
      dispatch({ type: SET_RULES_ACTION, payload: rules })
    } catch (error) {
      pricelogger('Cannot disable/enable')
      pricelogger(error)
      bugsnagClient.notify(
        error,
        {
          metaData: {
            component: {
              class: 'AppCampaigns',
              function: 'BackendAPI.updateTierPrice',
            },
            item: {
              itemId: ruleId,
            }
          },
        }
      )
      await rootContext.showNotification('Update state action fault', true)
    }
  }

  /**
   * Helper function. Enable/Disable state setter
   * @param ids
   * @param action
   */
  const runBulkChangeActiveState = async (ids, action) => {
    try {
      const response = await BackendAPI.bulkUpdateTierPrice(ids, { action })
      const clonedCampaigns = _.cloneDeep(state.rules)
      for (let campaignId of ids) {
        const updatedCampaign = clonedCampaigns.find(campaign => campaign.id + '' === campaignId + '')
        updatedCampaign.isActive = action === 'enable'
      }
      dispatch({ type: SET_RULES_ACTION, payload: clonedCampaigns })
      handleSelectionChange([])
      // dispatch({ type: SET_STATE_ACTION, payload: {rules: clonedCampaigns, selectedItems: []} })
    } catch (error) {
      pricelogger('Cannot bulk disable')
      pricelogger(error)
      bugsnagClient.notify(
        error,
        {
          metaData: {
            component: {
              class: 'AppCampaigns',
              function: 'BackendAPI.bulkUpdateTierPrice',
            },
          },
        }
      )
      await rootContext.showNotification('Bulk deactivate action fault', true)
    }
  }

  /**
   * Fetch filtered data
   */
  const handleFiltersChange = async (key, value) => {
    const clonedFilters = _.cloneDeep(state.filtersState)
    clonedFilters[key] = value
    try {
      const response = await fetchRules(1, state.sortValue, state.queryValue, clonedFilters)
      dispatch({
        type: SET_STATE_ACTION,
        payload: { pageNumber: 1, links: response.links, rules: response.data, filtersState: clonedFilters }
      })
    } catch (error) {
     pricelogger('handleFiltersChange fault')
     pricelogger(error)
    }
  }

  const handleChangeStatusFilter = (selection) => {
    handleFiltersChange(FILTER_BY_ACTIVE_STATE_KEY, selection[0])
  }

  const handleChangeAppliesToFilter = (selection) => {
    handleFiltersChange(FILTER_BY_APPLIES_TO_KEY, selection[0])
  }

  const handleChangePrerequisiteFilter = (selection) => {
    handleFiltersChange(FILTER_BY_PREREQUISITE_KEY, selection[0])
  }

  const handleChangeDiscountFilter = (selection) => {
    handleFiltersChange(FILTER_BY_DISCOUNT_KEY, selection[0])
  }

  const handleCampaignStateFilterRemove = () => {
    handleFiltersChange(FILTER_BY_ACTIVE_STATE_KEY, '')
  }

  const handleCampaignAppliesToFilterRemove = () => {
    handleFiltersChange(FILTER_BY_APPLIES_TO_KEY, '')
  }

  const handleCampaignPrerequisiteFilterRemove = () => {
    handleFiltersChange(FILTER_BY_PREREQUISITE_KEY, '')
  }

  const handleCampaignDiscountFilterRemove = () => {
    handleFiltersChange(FILTER_BY_DISCOUNT_KEY, '')
  }

  const disambiguateLabel = (key, value = '') => {
    switch (key) {
      case FILTER_BY_ACTIVE_STATE_KEY:
        return `Is active  ${value}`
      case FILTER_BY_APPLIES_TO_KEY:
        return `Applies to ${value}`
      case FILTER_BY_PREREQUISITE_KEY:
        return `Prerequisite is ${value}`
      case FILTER_BY_DISCOUNT_KEY:
        return `Discount in ${value}`
      default:
        return value
    }
  }

  const filters = [
    {
      key: FILTER_BY_ACTIVE_STATE_KEY,
      label: 'State',
      shortcut: true,
      filter: (
        <ChoiceList
          title='State'
          titleHidden
          choices={[
            { label: 'Active', value: FILTER_BY_ACTIVE_STATE_ACTIVE_VALUE },
            { label: 'Inactive', value: FILTER_BY_ACTIVE_STATE_INACTIVE_VALUE },
          ]}
          onChange={handleChangeStatusFilter}
          selected={state.filtersState[FILTER_BY_ACTIVE_STATE_KEY] || []}
        />
      )
    },
    {
      key: FILTER_BY_APPLIES_TO_KEY,
      label: disambiguateLabel(FILTER_BY_APPLIES_TO_KEY),
      shortcut: true,
      filter: (
        <ChoiceList
          title='Applies To'
          titleHidden
          choices={[
            { label: 'Entire order', value: FILTER_BY_APPLIES_TO_ORDER_VALUE },
            { label: 'Products', value: FILTER_BY_APPLIES_TO_PRODUCTS_VALUE },
            { label: 'Collections', value: FILTER_BY_APPLIES_TO_COLLECTIONS_VALUE },
          ]}
          onChange={handleChangeAppliesToFilter}
          selected={state.filtersState[FILTER_BY_APPLIES_TO_KEY] || []}
        />
      )
    },
    {
      key: FILTER_BY_PREREQUISITE_KEY,
      label: disambiguateLabel(FILTER_BY_PREREQUISITE_KEY),
      shortcut: true,
      filter: (
        <ChoiceList
          title='Prerequisite'
          titleHidden
          choices={[
            { label: 'Quantity', value: FILTER_BY_PREREQUISITE_QUANTITY_VALUE },
            { label: 'Amount', value: FILTER_BY_PREREQUISITE_AMOUNT_VALUE },
          ]}
          onChange={handleChangePrerequisiteFilter}
          selected={state.filtersState[FILTER_BY_PREREQUISITE_KEY] || []}
        />
      )
    },
    {
      key: FILTER_BY_DISCOUNT_KEY,
      label: disambiguateLabel(FILTER_BY_DISCOUNT_KEY),
      shortcut: true,
      filter: (
        <ChoiceList
          title='Discount'
          titleHidden
          choices={[
            { label: 'Percent', value: FILTER_BY_DISCOUNT_PERCENT_VALUE },
            { label: 'Amount', value: FILTER_BY_DISCOUNT_AMOUNT_VALUE },
          ]}
          onChange={handleChangeDiscountFilter}
          selected={state.filtersState[FILTER_BY_DISCOUNT_KEY] || []}
        />
      )
    }
  ]

  const appliedFilters = []
  if (!_.isEmpty(state.filtersState[FILTER_BY_ACTIVE_STATE_KEY])) {
    appliedFilters.push({
      key: FILTER_BY_ACTIVE_STATE_KEY,
      label: disambiguateLabel(FILTER_BY_ACTIVE_STATE_KEY, state.filtersState[FILTER_BY_ACTIVE_STATE_KEY]),
      onRemove: handleCampaignStateFilterRemove,
    })
  }

  if (!_.isEmpty(state.filtersState[FILTER_BY_APPLIES_TO_KEY])) {
    appliedFilters.push({
      key: FILTER_BY_APPLIES_TO_KEY,
      label: disambiguateLabel(FILTER_BY_APPLIES_TO_KEY, state.filtersState[FILTER_BY_APPLIES_TO_KEY]),
      onRemove: handleCampaignAppliesToFilterRemove,
    })
  }

  if (!_.isEmpty(state.filtersState[FILTER_BY_PREREQUISITE_KEY])) {
    appliedFilters.push({
      key: FILTER_BY_PREREQUISITE_KEY,
      label: disambiguateLabel(FILTER_BY_PREREQUISITE_KEY, state.filtersState[FILTER_BY_PREREQUISITE_KEY]),
      onRemove: handleCampaignPrerequisiteFilterRemove,
    })
  }

  if (!_.isEmpty(state.filtersState[FILTER_BY_DISCOUNT_KEY])) {
    appliedFilters.push({
      key: FILTER_BY_DISCOUNT_KEY,
      label: disambiguateLabel(FILTER_BY_DISCOUNT_KEY, state.filtersState[FILTER_BY_DISCOUNT_KEY]),
      onRemove: handleCampaignDiscountFilterRemove,
    })
  }

  pricelogger('AppCampaigns return')
  pricelogger(state)

  return (
    <Card sectioned title='Campaigns Overview' actions={[{
      content: 'Create new campaign',
      id: 'edith',
      onAction: () => {
        rootContext.dispatch({ type: SET_CAMPAIGN_ACTION, payload: getTierPriceTemplate(shopSettings) })
        history.push('/edit/0')
      }
    }]} primaryFooterAction={{
      content: 'Create new campaign',
      id: 'editf',
      onAction: () => {
        rootContext.dispatch({ type: SET_CAMPAIGN_ACTION, payload: getTierPriceTemplate(shopSettings) })
        history.push('/edit/0')
      }
    }}>
      <div className='RuleList__Wrapper'>
        <ResourceList
          loading={_.isEmpty(state.links)}
          resourceName={resourceName}
          items={state.rules}
          showHeader
          totalItemsCount={state.rules.length}
          selectedItems={state.selectedItems}
          onSelectionChange={handleSelectionChange}
          promotedBulkActions={[
            { content: 'Delete campaigns', onAction: handleBulkDelete },
          ]}
          bulkActions={[
            { content: 'Deactivate campaigns', onAction: handleBulkDisable },
            { content: 'Activate campaigns', onAction: handleBulkEnable },
          ]}
          sortOptions={sortOptions}
          sortValue={state.sortValue}
          onSortChange={handleSortChange}
          filterControl={
            <Filters
              filters={filters}
              queryValue={state.searchValue}
              onQueryChange={_.debounce(queryValue => handleSearchChange(queryValue), 50)}
              // onQueryChange={handleSearchChange}
              onClearAll={() => pricelogger('Clear All')}
              appliedFilters={appliedFilters}
            />
          }
          renderItem={
            (item) => {
              return (
                <ResourceItem
                  id={item.id}
                  key={item.id}
                  onClick={(id) => handleItemAction(id)}
                  accessibilityLabel={`View details for ${item.title}`}
                  // shortcutActions={shortcutActions}
                  name={item.title}
                >
                  <TierPriceListItem key={item.id} {...item} customActions={[
                    {
                      name: 'delete',
                      onAction: handleItemDeleteAction,
                    },
                    {
                      name: 'deactivate',
                      onAction: handleItemDisableAction,
                    },
                    {
                      name: 'activate',
                      onAction: handleItemEnableAction,
                    },
                  ]}
                  />
                </ResourceItem>
              )
            }
          }
        />
        {
          state.rules.length > 0
            ? (
              <TierPriceListFooter>
                <Pagination
                  hasPrevious={!state.isFirstPage}
                  hasNext={!state.isLastPage}
                  onPrevious={handlePreviousPage}
                  onNext={handleNextPage}
                  accessibilityLabel={`Pagination`}
                  plain
                />
              </TierPriceListFooter>
            )
            : null
        }
      </div>
    </Card>
  )
}

AppCampaigns.propTypes = {
  shopUrl: PropTypes.string,
  shopSettings: PropTypes.object,
  history: PropTypes.object.isRequired,
}
