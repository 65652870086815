import React, { useContext } from 'react'
import {
  Heading, Select, Tooltip, Button,
} from '@shopify/polaris'

import { CampaignContext, SET_CAMPAIGN_ACTION } from '@/components/editor'

const PREREQUISITE_AMOUNT = 'amount'
const PREREQUISITE_QUANTITY = 'quantity'
const DISCOUNT_PERCENT = 'percent'
const DISCOUNT_AMOUNT = 'amount'

export const CampaignOptions = (props) => {

  const campaignContext = useContext(CampaignContext)
  const { campaign, dispatch } = campaignContext

  const prerequisiteCount = [
    { label: 'Minimum purchase amount ($)', value: PREREQUISITE_AMOUNT, disabled: false },
    { label: 'Minimum quantity of items', value: PREREQUISITE_QUANTITY, disabled: false },
  ]

  const discountTypes = [
    { label: 'Percentage', value: DISCOUNT_PERCENT, disabled: campaign.isPrice },
    { label: 'Fixed amount', value: DISCOUNT_AMOUNT, disabled: false },
  ]

  const changeCampaignOption = (option, value) => {
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign[option] = value
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  const handleChangePrerequisiteCount = (selected, id) => {
    changeCampaignOption('prerequisiteCount', selected)
  }

  const handleChangeDiscountType = (selected, id) => {
    changeCampaignOption('discountType', selected)
  }

  const handleChangeIfEachItem = (value) => {
    changeCampaignOption('isEachItem', !campaign.isEachItem)
  }

  return (
    <div className='CampaignOptions'>
      <Heading>Levels</Heading>
      <div className='TierPriceEditor__PriceBreakType'>
        <Select
          label='Price break type'
          options={prerequisiteCount}
          onChange={handleChangePrerequisiteCount}
          value={_.isUndefined(campaign.prerequisiteCount) ? PREREQUISITE_QUANTITY : campaign.prerequisiteCount}
        />
      </div>
      <div className='TierPriceEditor__DiscountType'>
        <Tooltip
          content='Discount type "Percentage" is disabled if one of the "Price per item" product page tables is selected'>
          <Select
            label='Discount type'
            options={discountTypes}
            onChange={handleChangeDiscountType}
            value={_.isUndefined(campaign.discountType) ? DISCOUNT_PERCENT : campaign.discountType}
          />
        </Tooltip>
      </div>
      <div className='TierPriceEditor__DiscountOnce'>
        <Tooltip
          content='Disabled if one of the "Price per item" tables is selected, or if the campaign is for "Entire order", or if the discount type is "Percentage"'>
          <Button fullWidth
                  disabled={
                    (_.isUndefined(campaign.appliesTo) || (campaign.appliesTo === 'order')) ||
                    (_.isUndefined(campaign.discountType) || (campaign.discountType === DISCOUNT_PERCENT)) ||
                    campaign.isPrice
                  }
                  onClick={handleChangeIfEachItem}>
            {(campaign.isEachItem ? 'Apply to each eligible item in order' : 'Apply discount once per order')}
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}