import React, { useState, useEffect } from 'react'
import { Card, Tabs, Collapsible, Scrollable } from '@shopify/polaris'
import * as PropTypes from 'prop-types'

import { AppInfo } from '@/components/about'
import { Strings } from '@/components/utilities'
import { BackendAPI } from '@/api'

export const AboutApp = (props) => {
  const { name, location } = props
  const [application, setApplication] = useState({})
  const [selectedTab, setSelectedTab] = useState(0)
  const [privacy, setPrivacy] = useState({ __html: '' })
  const [manual, setManual] = useState({ __html: '' })
  const [description, setDescription] = useState({ __html: '' })

  useEffect(() => {
    BackendAPI.getAbout(Strings.getHostName(props.shopUrl))
      .then(response => {
        pricelogger('getAbout')
        pricelogger(response.data)
        setApplication(response.data.appStat)
        setDescription({ __html: response.data.docs.description })
        setPrivacy({ __html: response.data.docs.privacy })
        setManual({ __html: response.data.docs.manual })
      })
      .catch(error => {
        pricelogger('getAbout fault')
        pricelogger(error)
        bugsnagClient.notify(
          error,
          {
            metaData: {
              component: {
                class: 'AppAbout',
                function: 'BackendAPI.getAbout',
              }
            }
          }
        )
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
  }, [])

  const tabs = [
    {
      id: 'description',
      content: 'Description',
      accessibilityLabel: 'Short description of the application',
      panelID: 'description-content',
    },
    {
      id: 'privacy-policy',
      content: 'Privacy Policy',
      accessibilityLabel: 'Privacy Policy of the application',
      panelID: 'privacy-policy-content',
    },
    {
      id: 'manual-install',
      content: 'Manual Installation/Removal',
      accessibilityLabel: 'Instruction for the manual installation and uninstall',
      panelID: 'manual-install-content',
    },
  ]

  const handleTabChange = (selectedTabIndex) => {
    setSelectedTab(selectedTabIndex)
  }

  return (
    <div className='About__Wrapper'>
      <Card sectioned title={`About ${name} application`}>
        <Card.Section title='Version information'>
          <AppInfo {...props} {...application}/>
        </Card.Section>
        <Card.Section title='General information'>
          <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}/>
          <Collapsible open={selectedTab === 0} id={tabs[selectedTab].panelID}>
            <Scrollable shadow style={{ height: '500px' }}>
              <div className='About__Description' dangerouslySetInnerHTML={description}/>
            </Scrollable>
          </Collapsible>
          <Collapsible open={selectedTab === 1} id={tabs[selectedTab].panelID}>
            <Scrollable shadow style={{ height: '500px' }}>
              <div className='About__Privacy' dangerouslySetInnerHTML={privacy}/>
            </Scrollable>
          </Collapsible>
          <Collapsible open={selectedTab === 2} id={tabs[selectedTab].panelID}>
            <Scrollable shadow style={{ height: '500px' }}>
              <div className='About__Manual' dangerouslySetInnerHTML={manual}/>
            </Scrollable>
          </Collapsible>
        </Card.Section>
      </Card>
    </div>
  )
}

AboutApp.propTypes = {
  name: PropTypes.string,
  location: PropTypes.object.isRequired,
}
