import React from 'react'
import { Card, FormLayout, Checkbox, TextField } from '@shopify/polaris'

import * as PropTypes from 'prop-types'

export const ProductMessage = (props) => {
  const {
    title, showDescription, productTableHeader,
    showEntireOrderWarning, entireOrderWarning, breaksHeader,
    discountsHeader, maxBreaksNumber, hiddenBreaksWarning,
    breakTypeAmountTranslation, breakTypeQuantityTranslation,
    discountTypeAmountTranslation, discountTypePercentTranslation, onUpdate
  } = props

  const handleShowDescriptionChange = (showDescription) => {
    onUpdate({
      showDescription,
    })
  }

  const handleProductTableHeaderChange = (productTableHeader) => {
    onUpdate({
      productTableHeader,
    })
  }

  const handleShowEntireOrderWarningChange = (showEntireOrderWarning) => {
    onUpdate({
      showEntireOrderWarning,
    })
  }

  const handleEntireOrderWarningChange = (entireOrderWarning) => {
    onUpdate({
      entireOrderWarning,
    })
  }

  const handleBreaksHeaderChange = (breaksHeader) => {
    onUpdate({
      breaksHeader,
    })
  }

  const handleDiscountsHeaderChange = (discountsHeader) => {
    onUpdate({
      discountsHeader,
    })
  }

  const handleMaxBreaksNumberChange = (maxBreaksNumber) => {
    onUpdate({
      maxBreaksNumber,
    })
  }

  const handleHiddenBreaksWarningChange = (hiddenBreaksWarning) => {
    onUpdate({
      hiddenBreaksWarning,
    })
  }

  const handleBreakTypeAmountTranslationChange = (breakTypeAmountTranslation) => {
    onUpdate({
      breakTypeAmountTranslation,
    })
  }

  const handleBreakTypeQuantityTranslationChange = (breakTypeQuantityTranslation) => {
    onUpdate({
      breakTypeQuantityTranslation,
    })
  }

  const handleDiscountTypeAmountTranslationChange = (discountTypeAmountTranslation) => {
    onUpdate({
      discountTypeAmountTranslation,
    })
  }

  const handleDiscountTypePercentTranslationChange = (discountTypePercentTranslation) => {
    onUpdate({
      discountTypePercentTranslation,
    })
  }

  return (
    <div className='ProductMessage__Content'>
      <Card sectioned
        title={title}
      >
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Checkbox
                checked={showDescription}
                label='Show campaign description'
                onChange={handleShowDescriptionChange}
              />
              <TextField
                label='Table header'
                value={productTableHeader}
                onChange={handleProductTableHeaderChange}
                placeholder='Available Discounts'
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox
                checked={showEntireOrderWarning}
                label='Show entire order warning'
                onChange={handleShowEntireOrderWarningChange}
              />
              <TextField
                label='Entire order warning'
                value={entireOrderWarning}
                disabled={!showEntireOrderWarning}
                onChange={handleEntireOrderWarningChange}
                placeholder='Applicable to entire order'
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label='Breaks column header'
                value={breaksHeader}
                onChange={handleBreaksHeaderChange}
                placeholder='Break (at %s)'
              />
              <TextField
                label='Discounts column header'
                value={discountsHeader}
                onChange={handleDiscountsHeaderChange}
                placeholder='Discount (in %s)'
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label='Max. breaks number'
                type='number'
                min={1}
                max={100}
                value={maxBreaksNumber}
                onChange={handleMaxBreaksNumberChange}
              />
              <TextField
                label='Hidden breaks warning'
                value={hiddenBreaksWarning}
                onChange={handleHiddenBreaksWarningChange}
                placeholder='%s more break(s) are not visible here'
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label='Break by amount in English'
                value={'amount'}
                disabled
              />
              <TextField
                label='Break by amount translated'
                value={breakTypeAmountTranslation}
                onChange={handleBreakTypeAmountTranslationChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label='Break by quantity in English'
                value={'quantity'}
                disabled
              />
              <TextField
                label='Break by quantity translated'
                value={breakTypeQuantityTranslation}
                onChange={handleBreakTypeQuantityTranslationChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label='Discount by amount in English'
                value={'amount'}
                disabled
              />
              <TextField
                label='Discount by amount translated'
                value={discountTypeAmountTranslation}
                onChange={handleDiscountTypeAmountTranslationChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label='Discount by percent in English'
                value={'percent'}
                disabled
              />
              <TextField
                label='Discount by percent translated'
                value={discountTypePercentTranslation}
                onChange={handleDiscountTypePercentTranslationChange}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </div>
  )
}

ProductMessage.propTypes = {
  onUpdate: PropTypes.func,
  title: PropTypes.string.isRequired,
  showDescription: PropTypes.bool.isRequired,
  productTableHeader: PropTypes.string.isRequired,
  showEntireOrderWarning: PropTypes.bool.isRequired,
  entireOrderWarning: PropTypes.string.isRequired,
  breaksHeader: PropTypes.string.isRequired,
  discountsHeader: PropTypes.string.isRequired,
  maxBreaksNumber: PropTypes.any,
  hiddenBreaksWarning: PropTypes.string.isRequired,
  breakTypeAmountTranslation: PropTypes.string.isRequired,
  breakTypeQuantityTranslation: PropTypes.string.isRequired,
  discountTypeAmountTranslation: PropTypes.string.isRequired,
  discountTypePercentTranslation: PropTypes.string.isRequired,
}
