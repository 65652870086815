import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'
import {
  Checkbox,
  TextField,
  Heading,
} from '@shopify/polaris'

import { CampaignContext, SET_CAMPAIGN_ACTION } from '@/components/editor'

export const CampaignSpecials = (props) => {

  const campaignContext = useContext(CampaignContext)
  const { campaign, dispatch } = campaignContext

  const changeCampaignOption = (option, value) => {
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign[option] = value
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  const handleSetIsForCustomersToggle = (isforcustomers) => {
    changeCampaignOption('isForCustomers', !campaign.isForCustomers)
  }

  const handleChangeCustomersTag = (value) => {
    changeCampaignOption('customersTag', _.isEmpty(value) ? '' : value.trim())
  }

  const handleSetIsLinkedToggle = (islinked) => {
    changeCampaignOption('isLinked', !campaign.isLinked)
  }

  const handleChangeLinkUrl = (value) => {
    changeCampaignOption('linkurl', _.isEmpty(value) ? '' : value.trim())
  }

  return (
    <div className='CampaignCustomers'>
      <Heading>Campaign Specials</Heading>
      <div className='CampaignCustomers__EnableTags'>
        <Checkbox
          checked={campaign.isForCustomers}
          label='The campaign is for the special customers only.'
          onChange={handleSetIsForCustomersToggle}
          helpText={'If checked then only logged in customers will see this campaign'}
        />
      </div>
      <div className='CampaignCustomers__Tags'>
        <TextField
          type={'url'}
          label='Tag assigned to the special customers.'
          maxLength={2000}
          disabled={!campaign.isForCustomers}
          value={_.isNull(campaign.customersTag) || _.isUndefined(campaign.customersTag) ? '' : campaign.customersTag}
          onChange={handleChangeCustomersTag}
          helpText={'Only customers with this tag will see this campaign. Empty tag means "any logged in customer can see the campaign"'}
        />
      </div>
      <div className='CampaignCustomers__EnableLink'>
        <Checkbox
          checked={campaign.isLinked}
          label='Enable link the Campaign Description to a destination.'
          onChange={handleSetIsLinkedToggle}
        />
      </div>
      <div className='CampaignCustomers__Link'>
        <TextField
          type={'url'}
          label='Link URL. Leave blank for auto-linking to the product collection if the campaign is for collection(s).'
          maxLength={2000}
          disabled={!campaign.isLinked}
          value={_.isNull(campaign.linkurl) || _.isUndefined(campaign.linkurl) ? '' : campaign.linkurl}
          onChange={handleChangeLinkUrl}
          helpText={'The URL is neither checked, nor validated and will be used as is! Please, verify it before store.'}
        />
      </div>
    </div>
  )
}