window._ = require('lodash')

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let csrftoken = document.head.querySelector('meta[name="csrf-token"]')

if (csrftoken) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrftoken.content
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

window.bugsnag = require('@bugsnag/js')
window.bugsnagClient = window.bugsnag(
  {
    apiKey: document.head.querySelector('meta[name="bugsnag"]').content,
    appVersion: document.head.querySelector('meta[name="version"]').content,
    releaseStage: document.head.querySelector('meta[name="stage"]').content,
    notifyReleaseStages: ['production', 'staging'],
  }
)

// let shoptoken = document.head.querySelector('meta[name="shop-token"]')
//
// if (shoptoken) {
//   window.axios.defaults.headers.common['X-Shopify-Access-Token'] = shoptoken.content
// } else {
//   console.error('Shop token not found')
// }

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

window.pricelogger = (msg) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(msg)
  }
}
