export default class BackendAPI {
  static prefix = ''
  static tierprices = BackendAPI.prefix + 'tiers/'
  static settings = BackendAPI.prefix + 'settings/'
  static statistics = BackendAPI.prefix + 'stats/'
  static about = BackendAPI.prefix + 'about/'

  static getTierPrice (id) {
    let entry = BackendAPI.tierprices + id
    return BackendAPI.handleGet(entry, {})
  }

  static getTierPrices (parameters = {}) {
    let entry = BackendAPI.tierprices
    return BackendAPI.handleGet(entry, parameters)
  }

  static updateTierPrice (id, parameters = {}) {
    let entry = BackendAPI.tierprices + id
    return BackendAPI.handlePut(entry, parameters)
  }

  static createTierPrice (parameters = {}) {
    let entry = BackendAPI.tierprices
    return BackendAPI.handlePost(entry, parameters)
  }

  static bulkUpdateTierPrice (ids, parameters = {}) {
    let entry = BackendAPI.tierprices + 'bulk'
    return BackendAPI.handlePut(entry, { ids: ids, ...parameters })
  }

  static deleteTierPrice (id, parameters = {}) {
    let entry = BackendAPI.tierprices + id
    return BackendAPI.handleDelete(entry, parameters)
  }

  static bulkDeleteTierPrice (ids, parameters = {}) {
    let entry = BackendAPI.tierprices + 'bulk'
    return BackendAPI.handleDelete(entry, { ids: ids, ...parameters })
  }

  static getSettings (id) {
    let entry = BackendAPI.settings + id
    return BackendAPI.handleGet(entry, {})
  }

  static updateSettings (id, parameters = {}) {
    let entry = BackendAPI.settings + id
    return BackendAPI.handlePut(entry, parameters)
  }

  static patchTheme (shop, parameters = {}) {
    let entry = BackendAPI.settings + 'patchtheme/' + shop
    return BackendAPI.handlePost(entry, parameters)
  }

  static cleanupTheme (shop, parameters = {}) {
    let entry = BackendAPI.settings + 'removetheme/' + shop
    return BackendAPI.handleDelete(entry, parameters)
  }

  static customiseTheme (shop, parameters = {}) {
    let entry = BackendAPI.settings + 'customisetheme/' + shop
    return BackendAPI.handleGet(entry, parameters)
  }

  static getStatistics (shop, parameters = {}) {
    let entry = BackendAPI.statistics + shop
    return BackendAPI.handleGet(entry, {})
  }

  static resetCounters (shop, parameters = {}) {
    let entry = BackendAPI.statistics + shop
    return BackendAPI.handleDelete(entry, parameters)
  }

  static getAbout (shop, parameters = {}) {
    let entry = BackendAPI.about + shop
    return BackendAPI.handleGet(entry, {})
  }

  static handleGet (entry, parameters) {
    return axios.get(
      entry,
      {
        params: { ...parameters }
      }
    )
      .then(response => {
        return new Promise((resolve, reject) => {
          resolve(response)
        })
      })
      .catch(error => {
        BackendAPI.handleError(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
  }

  static handlePost (entry, parameters) {
    return axios.post(
      entry, { ...parameters }
    )
      .then(response => {
        return new Promise((resolve, reject) => {
          resolve(response)
        })
      })
      .catch(error => {
        BackendAPI.handleError(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
  }

  static handlePut (entry, parameters) {
    return axios.put(
      entry, { ...parameters }
    )
      .then(response => {
        return new Promise((resolve, reject) => {
          resolve(response)
        })
      })
      .catch(error => {
        BackendAPI.handleError(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
  }

  static handleDelete (entry, parameters) {
    return axios.delete(
      entry, { data: parameters }
    )
      .then(response => {
        return new Promise((resolve, reject) => {
          resolve(response)
        })
      })
      .catch(error => {
        BackendAPI.handleError(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })
  }

  static handleError (error) {
    if (error.response) {
      // The request was made and the server responded with a status code error.response.status
      pricelogger('Backend server error status: ' + error.response.status)
    } else if (error.request) {
      // The request was made but no response was received
      pricelogger('Backend server does not respond')
      pricelogger(error.request)
    } else {
      pricelogger(error.message)
    }
  }
}
