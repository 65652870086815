import React from 'react'

export const PriceAltTable = () => {
  return (
    <div className='ProductPageTables'>
      <h4>Table Header</h4>
      <div className='ProductPageTables__Description mamuta-smarty-camp-descr'>
        <p>
          Campaign Description (may be a link)
        </p>
      </div>
      <div className='mamuta-smarty-camp-container'>

        <div className='mamuta-smarty-camp-row2'>
          <div className='mamuta-smarty-camp-column'>
            Breaks column header
          </div>
          <div className='mamuta-smarty-camp-column'>
            Discounts column header
          </div>
        </div>

        <div className='mamuta-smarty-camp-row2'>
          <div className='mamuta-smarty-camp-column'>
            <span>$20.00</span>
            <span> - </span>
            <span>$29.99</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>$5.00 Suffix</span>
          </div>
        </div>

        <div className='mamuta-smarty-camp-row2 mamuta-smarty-camp-some'>
          <div className='mamuta-smarty-camp-column'>
            <span/>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>Hidden breaks warning</span>
          </div>
        </div>

        <div className='mamuta-smarty-camp-row2'>
          <div className='mamuta-smarty-camp-column'>
            <span>$40.00</span>
            <span> + </span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>$15.00 Suffix</span>
          </div>
        </div>

      </div>
    </div>
  )
}
