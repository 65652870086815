import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'
import {
  Checkbox,
  TextField,
  Heading, Icon, Select,
} from '@shopify/polaris'
import { CalendarMinor } from '@shopify/polaris-icons'

import { CampaignContext, SET_STATE_ACTION } from '@/components/editor'
import { Moments } from '@/components/utilities'

export const CampaignSchedule = (props) => {
  const campaignContext = useContext(CampaignContext)

  const { campaign, dispatch } = campaignContext
  const { timezone, startDate, endDate, setEndDate } = props
  const timeZones = Moments.getTzOptions()

  const prepareLocalDate = (dateFieldName, value) => {
    const timeZone = timezone
    const fixedDate = _.isNull(props[dateFieldName]) ? Moments.getCurrentMomentLocal(timeZone) : props[dateFieldName]
    return Moments.makeLocalMoment(value, timeZone)
      .hour(fixedDate.hour())
      .minute(fixedDate.minute())
      .second(fixedDate.second())
  }

  const setCampaignStart = (sDate, eDate) => {
    const clonedCampaign = _.cloneDeep(campaign)
    if (!_.isNull(eDate)) {
      if (sDate.isAfter(eDate)) {
        eDate = sDate.clone()
        eDate.add(1, 'minutes')
      }
      clonedCampaign.endsAt = eDate.clone().tz(Moments.TIMEZONE_GMT).format(Moments.DATE_TIME_FORMAT)
    }
    clonedCampaign.startsAt = sDate.clone().tz(Moments.TIMEZONE_GMT).format(Moments.DATE_TIME_FORMAT)
    dispatch({
      type: SET_STATE_ACTION, payload: {
        campaign: clonedCampaign,
        startDate: sDate,
        endDate: eDate,
        isDirty: true,
      }
    })
  }

  const handleStartDateChange = (value) => {
    pricelogger('handleStartDateChange ' + value)
    const startDate = prepareLocalDate('startDate', value)
    setCampaignStart(startDate, endDate)
  }

  const handleStartTimeChange = (value) => {
    pricelogger('handleStartTimeChange ' + value)
    const timeZone = timezone
    let sDate = _.isNull(startDate) ? Moments.getCurrentMomentLocal(timeZone) : startDate
    const str = sDate.format(Moments.DATE_FORMAT) + ' ' + value
    sDate = Moments.makeLocalMoment(str, timeZone)
    pricelogger('startDate ' + sDate.format(Moments.DATE_TIME_FORMAT))
    pricelogger(sDate)
    setCampaignStart(sDate, endDate)
  }

  const handleTimeZoneChange = (timeZone) => {
    const clonedCampaign = _.cloneDeep(campaign)
    const stDate = startDate.tz(timeZone)
    clonedCampaign.tz = timeZone
    clonedCampaign.startsAt = stDate.clone().tz(Moments.TIMEZONE_GMT).format(Moments.DATE_TIME_FORMAT)
    let eDate = null
    if (!_.isNull(eDate)) {
      eDate = endDate.tz(timeZone)
      clonedCampaign.endsAt = eDate.clone().tz(Moments.TIMEZONE_GMT).format(Moments.DATE_TIME_FORMAT)
    }
    dispatch({
      type: SET_STATE_ACTION, payload: {
        campaign: clonedCampaign,
        startDate: stDate,
        endDate: eDate,
        timezone: timeZone,
        isDirty: true,
      }
    })
  }

  const handleSetEndTimeToggle = (toggleEndDate) => {
    const clonedCampaign = _.cloneDeep(campaign)
    const sDate = startDate
    const timeZone = timezone
    const nEndDate = toggleEndDate ? Moments.makeLocalMoment(sDate.format(Moments.DATE_TIME_FORMAT), timeZone).add(1, 'minutes') : null
    clonedCampaign.endsAt = _.isNull(nEndDate) ? null : nEndDate.clone().tz(Moments.TIMEZONE_GMT).format(Moments.DATE_TIME_FORMAT)
    dispatch({
      type: SET_STATE_ACTION, payload: {
        campaign: clonedCampaign,
        setEndDate: !setEndDate,
        endDate: nEndDate,
        isDirty: true,
      }
    })
  }

  const setCampaignEnd = (sDate, eDate) => {
    if (sDate.isAfter(eDate)) {
      eDate = startDate.clone()
      eDate.add(1, 'minutes')
    }
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign.endsAt = eDate.clone().tz(Moments.TIMEZONE_GMT).format(Moments.DATE_TIME_FORMAT)
    dispatch({
      type: SET_STATE_ACTION, payload: {
        campaign: clonedCampaign,
        endDate: eDate,
        isDirty: true,
      }
    })
  }

  const handleEndDateChange = (value) => {
    pricelogger('handleEndDateChange ' + value)
    const eDate = prepareLocalDate('endDate', value)
    setCampaignEnd(startDate, eDate)
  }

  const handleEndTimeChange = (value) => {
    pricelogger('handleEndTimeChange ' + value)
    const timeZone = timezone
    let eDate = _.isNull(endDate) ? Moments.getCurrentMomentLocal(timeZone) : endDate
    const str = eDate.format(Moments.DATE_FORMAT) + ' ' + value
    eDate = Moments.makeLocalMoment(str, timeZone)
    setCampaignEnd(startDate, eDate)
  }

  return (
    <div className='CampaignActivePeriod'>
      <Heading>Campaign Schedule</Heading>
      <div className='ActivePeriod__StartDate'>
        <TextField type='date' label='Start date'
                   onChange={handleStartDateChange}
                   prefix={<Icon source={CalendarMinor}/>}
                   value={startDate.format(Moments.DATE_FORMAT)}
                   min={(startDate.isAfter(Moments.getCurrentMomentLocal(timezone)) ? Moments.getCurrentMomentLocal(timezone) : startDate).format(Moments.DATE_FORMAT)}
        />
      </div>
      <div className='ActivePeriod__StartTime'>
        <TextField type='time' label='Start time (in current Time Zone)'
                   onChange={handleStartTimeChange}
                   value={startDate.format(Moments.TIME_FORMAT)}
        />
      </div>
      <div className='ActivePeriod__TZ'>
        <Select
          label='Time Zone'
          options={timeZones}
          onChange={handleTimeZoneChange}
          value={timezone}
        />
      </div>
      <div className='ActivePeriod__SetEndDate'>
        <Checkbox
          checked={setEndDate}
          label='Set the end date. If not checked, the campaign runs forever.'
          onChange={handleSetEndTimeToggle}
        />
      </div>
      <div className='ActivePeriod__EndDate'>
        <TextField type='date' label='End date'
                   disabled={!setEndDate}
                   onChange={handleEndDateChange}
                   prefix={<Icon source={CalendarMinor}/>}
                   value={(_.isNull(endDate) ? '' : endDate.format(Moments.DATE_FORMAT))}
                   min={startDate.format(Moments.DATE_FORMAT)}
        />
      </div>
      <div className='ActivePeriod__EndTime'>
        <TextField type='time' label='End time (in current Time Zone)'
                   disabled={!setEndDate}
                   onChange={handleEndTimeChange}
                   value={(_.isNull(endDate) ? '' : endDate.format(Moments.TIME_FORMAT))}
        />
      </div>
    </div>
  )
}

CampaignSchedule.propTypes = {
  timezone: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setEndDate: PropTypes.any,
}
