import React from 'react'
import * as PropTypes from 'prop-types'

import { productPageTables } from '@/components/editor/tables'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ProductPageTables = ({ handle }) => {
  try {
    return productPageTables[handle]()
  } catch (error) {
    return null
  }
}

ProductPageTables.propTypes = {
  handle: PropTypes.string,
}

