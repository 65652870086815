import React, { useEffect, useReducer, useContext } from 'react'
import {
  Page,
  Layout,
  Card,
  FormLayout,
  Button,
  Stack,
  TextContainer,
  TextStyle, TextField, ChoiceList
} from '@shopify/polaris'
import { SaveMinor } from '@shopify/polaris-icons'
import { Context, TitleBar, Toast } from '@shopify/app-bridge-react'

import { CartMessage, ProductMessage } from '@/components/settings'
import { Strings } from '@/components/utilities'
import { gotoThemeCustomization } from '@/components/editor/sections'
import { BackendAPI } from '@/api'
import * as PropTypes from 'prop-types'
import { SET_CAMPAIGN_ACTION } from '@/components/editor'

const initialState = {
  id: -1,
  productTableHeader: '',
  showDescription: true,
  showEntireOrderWarning: true,
  entireOrderWarning: '',
  breaksHeader: '',
  discountsHeader: '',
  discountPrefix: '',
  hiddenBreaksWarning: '',
  maxBreaksNumber: 5,
  breakTypeAmountTranslation: 'amount',
  breakTypeQuantityTranslation: 'quantity',
  discountTypeAmountTranslation: 'amount',
  discountTypePercentTranslation: 'percent',
  showSuccessMessage: true,
  showSuccessPercentMessage: true,
  successSavedAmount: '',
  showUpsellMessage: true,
  showUpsellPercentMessage: true,
  upsellSavedAmount: '',
  isDirty: false,
  useDraftOrder: false,
  themeId: '',
  moneyFormat: '{{amount}}',
  noticeMessage: '',
  errorMessage: ''
}

const initState = (settingsId) => {
  return { ...initialState, id: settingsId }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setAll':
      return {
        ...state,
        ...action.payload
      }
    case 'setUseDraftOrder':
      return {
        ...state,
        useDraftOrder: action.payload,
        isDirty: true
      }
    case 'setDiscountPrefix':
      return {
        ...state,
        discountPrefix: action.payload,
        isDirty: true
      }
    case 'setProductPagePresentation':
      return {
        ...state,
        ...action.payload,
        isDirty: true
      }
    case 'setSuccessMessagePresentation':
      return {
        ...state,
        showSuccessMessage: action.payload.showMessage,
        showSuccessPercentMessage: action.payload.showPercent,
        successSavedAmount: action.payload.savedAmountMessage,
        isDirty: true,
      }
    case 'setUpsellMessagePresentation':
      return {
        ...state,
        showUpsellMessage: action.payload.showMessage,
        showUpsellPercentMessage: action.payload.showPercent,
        upsellSavedAmount: action.payload.savedAmountMessage,
        isDirty: true,
      }
    case 'saveSettings':
      return {
        ...state,
        ...action.payload,
        isDirty: false,
      }
    case 'setNoticeMessage':
      return {
        ...state,
        noticeMessage: action.payload
      }
    case 'setErrorMessage':
      return {
        ...state,
        errorMessage: action.payload
      }
    case 'reset':
      return initState(action.payload)
    default:
      return state
  }
}

/**
 * Settings page content. Separate component of the Application level
 * because of the Polaris requirements to the settings page layout.
 * @param props
 * @returns {*}
 * @constructor
 */
export const AppSettings = (props) => {
  const { settingsId, shopUrl } = props
  const [state, dispatch] = useReducer(reducer, settingsId, initState)
  const appBridge = useContext(Context)

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await BackendAPI.getSettings(settingsId)
        pricelogger('AppSettings useEffect settings')
        pricelogger(settings.data.data)
        const host = Strings.getHostName(shopUrl)
        const response = await BackendAPI.customiseTheme(host)
        const themeId = response.data + ''
        pricelogger('AppSettings useEffect ThemeID ' + themeId)
        dispatch({ type: 'setAll', payload: { ...settings.data.data, themeId } })
      } catch (error) {
        pricelogger('fetchSettings fault')
        pricelogger(error)
      }
    }
    fetchSettings()
  }, [settingsId, shopUrl])

  const handleDiscountPrefixChange = (discountPrefix) => { //
    pricelogger('saveProductPagePresentation')
    dispatch({ type: 'setDiscountPrefix', payload: discountPrefix })
  }

  const saveProductPagePresentation = (data) => { //
    pricelogger('saveProductPagePresentation')
    dispatch({ type: 'setProductPagePresentation', payload: data })
  }

  const saveSuccessMessagePresentation = (data) => { //
    pricelogger('saveSuccessMessagePresentation')
    dispatch({ type: 'setSuccessMessagePresentation', payload: data })
  }

  const saveUpsellMessagePresentation = (data) => { //
    pricelogger('saveUpsellMessagePresentation')
    dispatch({ type: 'setUpsellMessagePresentation', payload: data })
  }

  const patchThemeAction = async () => {
    pricelogger('patchThemeAction')
    const host = Strings.getHostName(shopUrl)
    try {
      const response = await BackendAPI.patchTheme(host)
      dispatch({ type: 'setNoticeMessage', payload: 'Main theme updated' })
      return response.data
    } catch (error) {
      pricelogger(error)
      bugsnagClient.notify(
        error,
        {
          metaData: {
            component: {
              class: 'AppSettings',
              function: 'BackendAPI.patchTheme',
            },
          },
        }
      )
      dispatch({ type: 'setErrorMessage', payload: 'Main theme cannot be updated' })
    }
  }

  const cleanupThemeAction = async () => {
    pricelogger('cleanupThemeAction')
    const host = Strings.getHostName(shopUrl)
    try {
      const response = await BackendAPI.cleanupTheme(host)
      dispatch({ type: 'setNoticeMessage', payload: 'Application removed from Main theme' })
      return response.data
    } catch (error) {
      pricelogger(error)
      bugsnagClient.notify(
        error,
        {
          metaData: {
            component: {
              class: 'AppSettings',
              function: 'BackendAPI.cleanupTheme',
            },
          },
        }
      )
      dispatch({ type: 'setErrorMessage', payload: 'Application cannot be removed from Main theme' })
    }
  }

  const saveSettings = async (data = {}) => { //
    pricelogger('saveSettings')
    pricelogger(data)
    pricelogger(state)
    try {
      const response = await BackendAPI.updateSettings(state.id, state)
      pricelogger('saveSettings saved data')
      pricelogger(response.data.data)
      dispatch({ type: 'saveSettings', payload: { ...response.data.data, noticeMessage: 'Settings saved' } })
    } catch (error) {
      pricelogger('saveSettings error')
      pricelogger(error)
      dispatch({ type: 'setErrorMessage', payload: 'Settings save fault!' })
    }
  }

  const handleChangeUseDraftOrder = (value) => {
    dispatch({ type: 'setUseDraftOrder', payload: value[0] === 'draft' })
  }

  pricelogger('AppSettings render')
  const {
    productTableHeader, showDescription, showEntireOrderWarning, entireOrderWarning, breaksHeader,
    discountsHeader, hiddenBreaksWarning, maxBreaksNumber,
    breakTypeAmountTranslation, breakTypeQuantityTranslation, discountTypeAmountTranslation, discountTypePercentTranslation,
    showSuccessMessage, showSuccessPercentMessage, successSavedAmount,
    showUpsellMessage, showUpsellPercentMessage, upsellSavedAmount, themeId, discountPrefix, useDraftOrder
  } = state
  return (
    <Page>
      <TitleBar
        title='Settings'
        breadcrumbs={[
          {
            content: 'Dashboard',
            url: '/',
            target: 'APP',
          }
        ]}
      />
      <Layout>
        <Layout.AnnotatedSection
          title='General settings'
          description='Theme and application settings'
        >
          <Card sectioned
                title='Theme management'
            // primaryFooterAction={
            //   {
            //     content: 'Save Settings',
            //     id: 'saveSettings',
            //     icon: 'save',
            //     onAction: this.saveSettings,
            //     disabled: !state.isDirty,
            //   }
            // }
          >
            <Card.Section>
              <TextStyle variation='subdued'>
                <TextContainer spacing='loose'>
                  <p>
                    Use "Patch Theme" to activate the application after a new main theme has
                    been published.
                  </p>
                  <p>
                    The application does all required updates during the installation
                    and this action is necessary <em>only</em> if you change the main
                    theme <em>after</em> the application has been installed.
                  </p>
                  <p>
                    Use "Cleanup Theme" to remove the application markup from the current main
                    theme.
                    Use with care - the tier prices will not be visible on the shop pages
                    afterwards.
                  </p>
                  <p>
                    Use "Customise Visual Settings" to tune the size, layout, and colors of the
                    information and messages on the product and shopping cart pages.
                    The contents of the information and the messages are set on this page below.
                    On the Editor page: Open "Theme settings" and select "Mamuta Smarty App"
                  </p>
                  <p/>
                </TextContainer>
              </TextStyle>
              <FormLayout>
                <FormLayout.Group condensed>
                  <Stack>
                    <Button destructive disabled={_.isEmpty(themeId)} onClick={patchThemeAction}>Patch Theme</Button>
                    <Button destructive disabled={_.isEmpty(themeId)} onClick={cleanupThemeAction}>Cleanup
                      Theme</Button>
                    <Button primary external onClick={() => gotoThemeCustomization(themeId, appBridge)}
                            disabled={_.isEmpty(themeId)}>
                      Customise Visual Settings
                    </Button>
                  </Stack>
                </FormLayout.Group>
                <TextStyle variation='negative'>
                  <TextContainer spacing='loose'>
                    <p>These actions modify your shop's main theme!</p>
                  </TextContainer>
                </TextStyle>
              </FormLayout>
            </Card.Section>
            <Card.Section>
              <div className='TierPriceSettings__UseDraftOrder'>
                <ChoiceList
                  title={'Choose discount application method'}
                  choices={[
                    { label: 'Application creates Draft Orders with discount', value: 'draft' },
                    { label: 'Application creates Discount Codes', value: 'code' },
                  ]}
                  selected={useDraftOrder ? 'draft' : 'code'}
                  onChange={handleChangeUseDraftOrder}
                />
                <TextContainer spacing='loose'>
                  <p/>
                  <p>
                    If multicurrency is enabled in the store settings, and
                    the customer's cart currency is not the same as the store currency then
                    the application creates Discount Code regardless of this setting.
                  </p>
                  <p>
                    Draft Order can be created in the terms of the store currency only.
                    See Shopify documentation for the details.
                  </p>
                  <p>
                    All Express Payment methods utilise Discount Code regardless of this setting.
                  </p>
                </TextContainer>
              </div>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Product Page'
          description='Presentation settings for the product page'
        >
          <div className={'ProductMessage__Wrapper--open'}>
            <ProductMessage
              title='Discounts table presentation defaults'
              showDescription={showDescription}
              productTableHeader={productTableHeader}
              showEntireOrderWarning={showEntireOrderWarning}
              entireOrderWarning={entireOrderWarning}
              breaksHeader={breaksHeader}
              discountsHeader={discountsHeader}
              maxBreaksNumber={maxBreaksNumber}
              hiddenBreaksWarning={hiddenBreaksWarning}
              breakTypeAmountTranslation={breakTypeAmountTranslation}
              breakTypeQuantityTranslation={breakTypeQuantityTranslation}
              discountTypeAmountTranslation={discountTypeAmountTranslation}
              discountTypePercentTranslation={discountTypePercentTranslation}
              onUpdate={saveProductPagePresentation}
            />
          </div>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Cart Page'
          description='Cart page messages'
        >
          <div className={'SuccessMessage__Wrapper--open'}>
            <CartMessage
              title='Success message presentation defaults'
              onUpdate={saveSuccessMessagePresentation}
              showMessage={showSuccessMessage}
              showPercent={showSuccessPercentMessage}
              savedAmountMessage={successSavedAmount}
            />
          </div>
          <div className={'UpsellMessage__Wrapper--open'}>
            <CartMessage
              title='Upsell message presentation defaults'
              onUpdate={saveUpsellMessagePresentation}
              showMessage={showUpsellMessage}
              showPercent={showUpsellPercentMessage}
              savedAmountMessage={upsellSavedAmount}
            />
          </div>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Checkout'
          description='Checkout messages'
        >
          <div className={'DiscountPrefix__Wrapper'}>
            <Card sectioned
                  title='Discount'
                  primaryFooterAction={
                    {
                      content: 'Save All Settings',
                      id: 'saveSettings',
                      icon: SaveMinor,
                      onAction: saveSettings,
                      disabled: !state.isDirty,
                    }
                  }
            >
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      label='Discount Code Prefix'
                      value={discountPrefix}
                      onChange={handleDiscountPrefixChange}
                      helpText={'The discount code presented to the customers and listed in your shop "Discounts" section looks like: <your prefix>_YYYYMMDDhhmmssuuu, e.g. "sales20_20190621220317893"'}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
            </Card>
          </div>
        </Layout.AnnotatedSection>
      </Layout>
      {
        state.noticeMessage.length > 0
          ? <Toast content={state.noticeMessage} onDismiss={() => dispatch({ type: 'setNoticeMessage', payload: '' })}
                   duration={2000}/>
          : null
      }
      {
        state.errorMessage.length > 0
          ? <Toast content={state.errorMessage} onDismiss={() => dispatch({ type: 'setErrorMessage', payload: '' })}
                   duration={2000} error/>
          : null
      }
    </Page>
  )
}

AppSettings.propTypes = {
  settingsId: PropTypes.string,
  shopUrl: PropTypes.string,
}
