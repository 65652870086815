import React from 'react'
import * as PropTypes from 'prop-types'

/**
 * Footer pagination and actions holder
 */
export const TierPriceListFooter = ({ children }) => {
  return (
    <div className='RuleListFooter'>
      {children}
    </div>
  )
}

TierPriceListFooter.propTypes = {
  children: PropTypes.any,
}
