import React from 'react'
import { Checkbox, TextField } from '@shopify/polaris'

import * as PropTypes from 'prop-types'

export const ProductPageTableSettings = (props) => {
  const {
    showDescription, productTableHeader,
    showEntireOrderWarning, entireOrderWarning, breaksHeader,
    discountsHeader, maxBreaksNumber, hiddenBreaksWarning, onUpdate
  } = props

  const handleShowDescriptionChange = (productTableShowDescription) => {
    onUpdate({
      productTableShowDescription,
    })
  }

  const handleProductTableHeaderChange = (productTableHeader) => {
    onUpdate({
      productTableHeader,
    })
  }

  const handleShowEntireOrderWarningChange = (productTableShowEntireOrderWaring) => {
    onUpdate({
      productTableShowEntireOrderWaring,
    })
  }

  const handleEntireOrderWarningChange = (productTableEntireOrderWaring) => {
    onUpdate({
      productTableEntireOrderWaring,
    })
  }

  const handleBreaksHeaderChange = (productTableBreaksHeader) => {
    onUpdate({
      productTableBreaksHeader,
    })
  }

  const handleDiscountsHeaderChange = (productTableDiscountsHeader) => {
    onUpdate({
      productTableDiscountsHeader,
    })
  }

  const handleMaxBreaksNumberChange = (productTableMaxBreaksNumber) => {
    onUpdate({
      productTableMaxBreaksNumber,
    })
  }

  const handleHiddenBreaksWarningChange = (productTableHiddenBreaksWarning) => {
    onUpdate({
      productTableHiddenBreaksWarning,
    })
  }

  return (
    <div className='ProductPageTables__Settings'>
      <div className='ProductPageTables__ShowDescription'>
        <Checkbox
          checked={showDescription}
          label='Show campaign description'
          onChange={handleShowDescriptionChange}
        />
      </div>
      <div className='ProductPageTables__TableHeader'>
        <TextField
          label='Table header'
          value={productTableHeader}
          onChange={handleProductTableHeaderChange}
          placeholder='Available Discounts'
        />
      </div>
      <div className='ProductPageTables__ShowEntireOrderWarning'>
        <Checkbox
          checked={showEntireOrderWarning}
          label='Show entire order warning'
          onChange={handleShowEntireOrderWarningChange}
        />
      </div>
      <div className='ProductPageTables__EntireOrderWarning'>
        <TextField
          label='Entire order warning'
          value={entireOrderWarning}
          disabled={!showEntireOrderWarning}
          onChange={handleEntireOrderWarningChange}
          placeholder='Applicable to entire order'
        />
      </div>
      <div className='ProductPageTables__BreaksHeader'>
        <TextField
          label='Breaks column header'
          value={breaksHeader}
          onChange={handleBreaksHeaderChange}
          placeholder='Break (at %s)'
        />
      </div>
      <div className='ProductPageTables__DiscountHeader'>
        <TextField
          label='Discounts column header'
          value={discountsHeader}
          onChange={handleDiscountsHeaderChange}
          placeholder='Discount (in %s)'
        />
      </div>
      <div className='ProductPageTables__MaxBreaks'>
        <TextField
          label='Max. breaks number'
          type='number'
          min={1}
          max={100}
          value={maxBreaksNumber}
          onChange={handleMaxBreaksNumberChange}
        />
      </div>
      <div className='ProductPageTables__HiddenBreaks'>
        <TextField
          label='Hidden breaks warning'
          value={hiddenBreaksWarning}
          onChange={handleHiddenBreaksWarningChange}
          placeholder='%s more break(s) are not visible here'
        />
      </div>
    </div>
  )
}

ProductPageTableSettings.propTypes = {
  onUpdate: PropTypes.func,
  showDescription: PropTypes.bool.isRequired,
  productTableHeader: PropTypes.string.isRequired,
  showEntireOrderWarning: PropTypes.bool.isRequired,
  entireOrderWarning: PropTypes.string.isRequired,
  breaksHeader: PropTypes.string.isRequired,
  discountsHeader: PropTypes.string.isRequired,
  maxBreaksNumber: PropTypes.any,
  hiddenBreaksWarning: PropTypes.string.isRequired,
}
