import React from 'react'
import { ResourceList, TextStyle, Button, Tooltip } from '@shopify/polaris'
import { DeleteMinor } from '@shopify/polaris-icons'

import * as PropTypes from 'prop-types'

export const ShopifyVariantListItem = (props) => {
  const { id, title, shopifyId, productId, shortcutActions, customActions, singleVariant } = props
  const infoBlock = (
    <div className='TierPriceEditor__ShopifyEntities--info'>
      <h3 className='TierPriceEditor__ShopifyEntities--title'>
        <TextStyle variation='strong'>{title}</TextStyle>
      </h3>
    </div>
  )
  const discTypeBlock = null

  return (
    <div className='TierPriceEditor__ShopifyVariant'>
      <ResourceList.Item
        id={shopifyId}
        onClick={() => {}}
        shortcutActions={shortcutActions}
      >
        <div className='TierPriceEditor__ShopifyVariant--content'>
          <div className='TierPriceEditor__ShopifyVariant--main'>
            {infoBlock}
            {discTypeBlock}
          </div>
        </div>
      </ResourceList.Item>
      <div className='TierPriceEditor__ShopifyVariant--customActions'>
        {
          customActions.map(
            (action, index) => (
              <Tooltip key={shopifyId + ':' + index} content={singleVariant ? 'Single variant cannot be deleted' : 'Delete this variant'}>
                <Button
                  id={shopifyId}
                  key={shopifyId + '-' + index}
                  disabled={singleVariant}
                  children={action.name}
                  accessibilityLabel={`Item action ${action.name}`}
                  onClick={action.onAction}
                  icon={DeleteMinor}
                  ariaControls={productId}
                />
              </Tooltip>
            )
          )
        }
      </div>
    </div>
  )
}

ShopifyVariantListItem.propTypes = {
  id: PropTypes.any,
  shopifyId: PropTypes.any,
  productId: PropTypes.any,
  title: PropTypes.string,
  customActions: PropTypes.array,
  shortcutActions: PropTypes.array,
  singleVariant: PropTypes.bool,
}
