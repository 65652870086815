import React, { useState, useEffect, useReducer, useRef } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { FooterHelp, Card, Layout, ButtonGroup, Button, TextContainer, Banner, List, Link } from '@shopify/polaris'
import { CirclePlusMinor } from '@shopify/polaris-icons'
import { Context, Toast } from '@shopify/app-bridge-react'

import * as PropTypes from 'prop-types'

import { routes, setRouteComponentProp } from '@/routes'
import { BackendAPI } from '@/api'
import { getTierPriceTemplate } from '@/components/utilities'

export const RootContext = React.createContext({})

export const SET_CAMPAIGN_ACTION = 'setCampaign'
export const SET_COMPONENT_ACTION = 'setComponent'
export const SHOW_NOTICE_ACTION = 'setNoticeMessage'
export const SHOW_ERROR_ACTION = 'setErrorMessage'
export const DISMISS_BANNER = 'dismissBanner'

const initialState = {
  component: null,
  currentItem: {},
  noticeMessage: '',
  errorMessage: '',
  showBanner: true,
}

/**
 *
 * @param component
 * @returns {*}
 */
const initState = (component) => {
  return _.merge(initialState, { component })
}

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case SET_COMPONENT_ACTION:
      const newState = _.merge(state, { component: action.payload })
      return { ...newState }
    case SET_CAMPAIGN_ACTION:
      if (_.isEmpty(action.payload)) {
        return { ...state, currentItem: {} }
      } else {
        return { ...state, currentItem: action.payload }
        // const newState = _.merge(state, {currentItem: action.payload})
        // return {...newState}
      }
    case SHOW_NOTICE_ACTION:
      return {
        ...state,
        noticeMessage: action.payload
      }
    case SHOW_ERROR_ACTION:
      return {
        ...state,
        errorMessage: action.payload
      }
    case DISMISS_BANNER:
      return {
        ...state,
        showBanner: false
      }
    case 'reset':
      return initState(action.payload)
    default:
      return state
  }
}

const shop = window.SHOP
/**
 * Application top level component.
 * Defines the routing and holds the data.
 *
 * @returns {*}
 * @constructor
 * @param props
 */
const AppRoot = (props) => {
  const { name, shopUrl, version, debug, shopSettings, location, history, shop } = props
  window.console.log(props)
  window.console.log(shop)
  const [state, dispatch] = useReducer(reducer, location.pathname, initState)
  const banner = useRef()
  // console.log(props)
  // console.log(routes)
  useEffect(() => {
    pricelogger('AppRoot Effect')
    if (typeof banner.current !== 'undefined' && banner.current !== null) {
      banner.current.focus()
    }
  }, [])
  // const app = useContext(Context)
  // console.log('PROVIDER Context ', app)

  const showNotification = (message, isError = false) => isError ? dispatch({
    type: SHOW_ERROR_ACTION,
    payload: message
  }) : dispatch({ type: SHOW_NOTICE_ACTION, payload: message })
  const dismissBanner = () => dispatch({ type: DISMISS_BANNER, payload: '' })
  const showGist = () => {
    window.gist.chat('open')
  }
  /**
   *
   * @param item
   * @param updateBackend
   * @returns {Q.Promise<unknown>}
   */
  const setItem = (item, updateBackend = false) => {
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: item })
    if (!updateBackend) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    }
    pricelogger('AppRoot update the backend')
    pricelogger(item)
    let promise = _.isUndefined(item.id) || _.isNull(item.id) || item.id + '' === 0 + ''
      ? BackendAPI.createTierPrice({ ...item })
      : BackendAPI.updateTierPrice(item.id, { ...item })
    return promise
      .then(response => {
        pricelogger('AppRoot saved data')
        return response.data
      })
      .catch(error => {
        pricelogger(error)
        bugsnagClient.notify(
          error,
          {
            metaData: {
              component: {
                class: 'AppRoot',
                function: 'setItem',
              },
              item: {
                itemId: item.id,
              }
            },
            beforeSend: report => {
              if (report.metaData.item.itemId + '' === 0 + '') {
                report.ignore()
              }
            }
          }
        )
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }).then(response => {
        pricelogger('AppRoot finally')
        pricelogger(response.data)
        dispatch({ type: SET_CAMPAIGN_ACTION, payload: response.data })
        return new Promise((resolve, reject) => {
          resolve(response)
        })
      })
  }

  /**
   *
   * @param id
   * @returns {*}
   */
  const getItem = async (id = undefined) => {
    if (_.isUndefined(id) || (!_.isEmpty(state.currentItem) && state.currentItem.id + '' === id + '')) {
      if (id === 0) {
        dispatch({ type: SET_CAMPAIGN_ACTION, payload: {} })
      }
      return { data: state.currentItem }
    }

    try {
      const response = await BackendAPI.getTierPrice(id)
      dispatch({ type: SET_CAMPAIGN_ACTION, payload: response.data })
      return response.data
    } catch (error) {
      pricelogger('fetchRules fault')
      pricelogger(error)
      bugsnagClient.notify(
        error,
        {
          metaData: {
            component: {
              class: 'AppRoot',
              function: 'getItem',
            },
            item: {
              itemId: id,
            }
          },
          beforeSend: report => {
            if (report.metaData.item.itemId + '' === 0 + '') {
              report.ignore()
            }
          }
        }
      )
      dispatch({ type: SET_CAMPAIGN_ACTION, payload: {} })
    }
  }

  setRouteComponentProp({
    shopUrl,
    shopSettings,
    name,
    version,
    debug,
    shop
  })

  return (
    <Layout>
      <Layout.Section>
        <div className='app-nav-button-group'>
          {
            JSON.parse(window.SHOP).is_free
              ?
              <div className='Customer__Banner'>
                <TextContainer>
                  <Banner
                    title="Upgrade To Premium Today!"
                    status="warning"
                    icon="%3Csvg%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%200c5.514%200%2010%204.486%2010%2010s-4.486%2010-10%2010S0%2015.514%200%2010%204.486%200%2010%200zm1%208.414l1.293%201.293a1%201%200%20101.414-1.414l-3-3a.998.998%200%2000-1.414%200l-3%203a1%201%200%20001.414%201.414L9%208.414V14a1%201%200%20102%200V8.414z%22%20fill%3D%22%235C5F62%22%2F%3E%3C%2Fsvg%3E%0A"
                    action={{
                      url: '/upgrade?shop=' + shopUrl,
                      content: 'Upgrade Now'
                    }}
                    >

                      <p>You're currently on our free plan, which allows you to create a single discount campaign for 'whole shop' discounts only.</p>
                      <p>To create more advanced campaigns, offering discounts on specific products or entire collections, or to use tiered pricing, please upgrade.</p>

                  </Banner>
                </TextContainer>
              </div>
              : null
          }

          {/*{
            state.showBanner
              ?
              <div className='Customer__Banner'>
                <TextContainer>
                  <Banner
                    title="Dear Customer!"
                    onDismiss={() => dismissBanner()}
                    ref={banner}
                    status="info"
                  >
                    <p/>
                    <List>
                      <List.Item>
                        If you experience any technical issues with our application, feel free to open a ticket{' '}
                        <Link url="https://smartydiscounts.freshdesk.com/support/tickets/new" external>here</Link>. We
                        will
                        answer you ASAP.
                      </List.Item>
                      <List.Item>
                        If you are happy with our application, please leave a review <Link
                        url="https://apps.shopify.com/smarty-discounts#reviews" external>here</Link>
                      </List.Item>
                    </List>
                    <p/>
                  </Banner>
                </TextContainer>
              </div>
              : null
          }*/}
          <ButtonGroup segmented>
            {
              location.pathname.indexOf('edit') < 0
                ? <Button
                  id={'create_campaign'}
                  key={'create_campaign'}
                  primary
                  icon={CirclePlusMinor}
                  size={'large'}
                  onClick={() => {
                    dispatch({ type: SET_CAMPAIGN_ACTION, payload: getTierPriceTemplate(shopSettings) })
                    history.push('/edit/0')
                  }
                  }
                  accessibilityLabel={'Create New Campaign'}
                >
                  &nbsp; Create New Campaign
                </Button>
                : null
            }
            {
              routes.filter(route => route.navbar && location.pathname !== route.path).map(
                (route, index) => (
                  <Button
                    id={route.key}
                    children={_.startCase(route.key)}
                    url={_.isUndefined(route.url) ? '' : route.url}
                    accessibilityLabel={`Navigate to ${route.key}`}
                    onClick={() => {
                      history.push(route.path)
                      window.analytics.page(route.key, { path: route.path })
                    }}
                    plain
                    key={route.key}
                  />
                )
              )
            }
          </ButtonGroup>
        </div>
      </Layout.Section>
      <Layout.Section>
        <RootContext.Provider value={{
          currentItem: state.currentItem,
          showBanner: state.showBanner,
          showNotification,
          setItem,
          getItem,
          dispatch,
          dismissBanner
        }}>
          {
            routes.map(
              (route, index) => <Route {...route}/>
            )
          }
        </RootContext.Provider>
        {
          state.noticeMessage.length > 0
            ? <Toast content={state.noticeMessage} onDismiss={() => dispatch({ type: SHOW_NOTICE_ACTION, payload: '' })}
                     duration={2000}/>
            : null
        }
        {
          state.errorMessage.length > 0
            ? <Toast content={state.errorMessage} onDismiss={() => dispatch({ type: SHOW_ERROR_ACTION, payload: '' })}
                     duration={2000}/>
            : null
        }
      </Layout.Section>
      <Layout.Section>
        <FooterHelp>
          <p>Some custom themes need a little help to display discounts.</p>
          <p>If you don't see yours right away,  or if you need any other help, please <a href='#' onClick={showGist}>contact support</a> - we're here to help!</p>
        </FooterHelp>
      </Layout.Section>
    </Layout>
  )
}

AppRoot.propTypes = {
  name: PropTypes.string,
  shopUrl: PropTypes.string,
  version: PropTypes.string,
  debug: PropTypes.string,
  shopSettings: PropTypes.object,
  shop: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

// wrap to make the routing properties available to all components of the application
export default withRouter(AppRoot)
