import { Redirect } from '@shopify/app-bridge/actions'

export { CampaignGeneral } from '@/components/editor/sections/CampaignGeneral'
export { CampaignDisplay } from '@/components/editor/sections/CampaignDisplay'
export { CampaignOptions } from '@/components/editor/sections/CampaignOptions'
export { CampaignLevels } from '@/components/editor/sections/CampaignLevels'
export { CampaignType } from '@/components/editor/sections/CampaignType'
export { CampaignSpecials } from '@/components/editor/sections/CampaignSpecials'
export { CampaignSchedule } from '@/components/editor/sections/CampaignSchedule'

export const hasErrorStates = errs => Object.values(errs).filter(e => !_.isEmpty(e)).length > 0

export const gotoThemeCustomization = (id, appBridge) => {
  const redirect = Redirect.create(appBridge)
  redirect.dispatch(Redirect.Action.ADMIN_PATH, {
    path: `/themes/${id}/editor`,
    newContext: true,
  })
}

