import React from 'react'
import ReactDOM from 'react-dom'
import * as PropTypes from 'prop-types'
import { HashRouter as Router } from 'react-router-dom'

import bugsnagReact from '@bugsnag/plugin-react'

import { Provider, TitleBar, Loading } from '@shopify/app-bridge-react'
import { AppProvider, Page } from '@shopify/polaris'

import { AppRoot, AppSettings } from '@/components'

/**
 * Application topmost component
 * @param name application name
 * @param shopUrl
 * @param version
 * @param debug true if debug build and/or debug environment
 * @param shopSettings
 * @returns {*}
 * @constructor
 */
const Application = ({ name, shopUrl, version, debug, shopSettings }) => {
  // If your effect returns a function, React will run it when it is time to clean up
  // useEffect(() => {
  //   return function cleanup () {
  //     pricelogger('Application Cleanup')
  //   }
  // })

  return (
    <Page
      title={name}
      secondaryActions={[
        {
          content: 'Settings',
          icon: '%3Csvg%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M9.027%200a1%201%200%2000-.99.859l-.37%202.598A6.993%206.993%200%20005.742%204.57L3.305%203.59a1%201%200%2000-1.239.428L.934%205.981a1%201%200%2000.248%201.287l2.066%201.621a7.06%207.06%200%20000%202.222l-2.066%201.621a1%201%200%2000-.248%201.287l1.132%201.962a1%201%200%20001.239.428l2.438-.979a6.995%206.995%200%20001.923%201.113l.372%202.598a1%201%200%2000.99.859h2.265a1%201%200%2000.99-.859l.371-2.598a6.995%206.995%200%20001.924-1.112l2.438.978a1%201%200%20001.238-.428l1.133-1.962a1%201%200%2000-.249-1.287l-2.065-1.621a7.063%207.063%200%20000-2.222l2.065-1.621a1%201%200%2000.249-1.287l-1.133-1.962a1%201%200%2000-1.239-.428l-2.437.979a6.994%206.994%200%2000-1.924-1.113L12.283.86a1%201%200%2000-.99-.859H9.027zm1.133%2013a3%203%200%20100-6%203%203%200%20000%206z%22%20fill%3D%22%235C5F62%22%2F%3E%3C%2Fsvg%3E%0A',
          url: '/settings',
          target: 'APP',
        },
      ]}
    >
      <Router render={({ location }) => {
        window.console.log('router render', location)
        if (typeof window.analytics.track === 'function') {
          window.analytics.page('Page View', { url: location.pathname + location.search })
        }
        return null;
      }}>
        <AppRoot shopUrl={shopUrl} shopSettings={JSON.parse(shopSettings)} name={name} version={version} debug={debug}/>
      </Router>
    </Page>
  )
}

Application.propTypes = {
  name: PropTypes.string,
  shopUrl: PropTypes.string,
  version: PropTypes.string,
  debug: PropTypes.string,
  shopSettings: PropTypes.string,
}

/**
 * Application init
 * @param name application name
 * @param shopUrl
 * @param shopOrigin
 * @param apiKey
 * @param version
 * @param debug true if debug build and/or debug environment
 * @param shopSettings
 * @param appSettings
 * @returns {*}
 * @constructor
 */
const SmartyDiscountsInit = ({ name, shop, shopUrl, shopOrigin, apiKey, version, debug, shopSettings, appSettings }) => {
  const renderSettings = appSettings.length > 0 && appSettings !== 0
  bugsnagClient.user = {
    id: shopUrl
  }
  bugsnagClient.metaData = {
    application: {
      name,
      key: apiKey,
      settingsID: renderSettings ? appSettings : 'not settings',
    }
  }
  bugsnagClient.use(bugsnagReact, React)
  const ErrorBoundary = bugsnagClient.getPlugin('react')

  return (
    document.getElementById('app')
      ? <ErrorBoundary>
        {
          renderSettings
            ? <AppSettings name={name} shopUrl={shopUrl} version={version}
                           debug={debug} settingsId={appSettings}/>
            : <Application name={name} shopUrl={shopUrl} version={version}
                           debug={debug} shopSettings={shopSettings} shop={shop}/>
        }
      </ErrorBoundary>
      : null
  )
}

SmartyDiscountsInit.propTypes = {
  name: PropTypes.string.isRequired,
  shopUrl: PropTypes.string.isRequired,
  shopOrigin: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  debug: PropTypes.string.isRequired,
  shopSettings: PropTypes.string.isRequired,
  appSettings: PropTypes.string.isRequired,
  shop: PropTypes.object,
}

const providerConfig = {
  shopOrigin: window.SHOPIFY_SHOP_ORIGIN,
  apiKey: window.SHOPIFY_APP_KEY,
  forceRedirect: true
}

ReactDOM.render(
  <AppProvider i18n={{}}>
    <Provider config={providerConfig}>
      <SmartyDiscountsInit name={window.APP_NAME} shopUrl={window.SHOPIFY_SHOP_URL}
                           shopOrigin={window.SHOPIFY_SHOP_ORIGIN} apiKey={window.SHOPIFY_APP_KEY}
                           version={window.APP_VERSION}
                           shop={window.SHOP}
                           debug={window.SHOPIFY_DEBUG} shopSettings={window.SHOP_SETTINGS}
                           appSettings={window.APP_SETTINGS}/>
    </Provider>
  </AppProvider>,
  document.getElementById('app')
)

export default Application
