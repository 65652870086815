import React, { useState } from 'react'
import { ResourceList, TextStyle, Button, Collapsible } from '@shopify/polaris'
import * as PropTypes from 'prop-types'
import { DeleteMinor } from '@shopify/polaris-icons'

import { ShopifyVariantListItem } from '@/components/editor'

export const ShopifyEntityListItem = (props) => {
  const { title, variants, shopifyId, onClick, onVariantAction, shortcutActions, customActions } = props
  const [variantsOpen, setVariantsOpen] = useState(false)
  const infoBlock = (
    <div className='TierPriceEditor__ShopifyEntities--info'>
      <h3 className='TierPriceEditor__ShopifyEntities--title'>
        <TextStyle variation='strong'>{title}</TextStyle>
      </h3>
    </div>
  )
  const discTypeBlock = null

  const toggleVariantsOpen = () => setVariantsOpen(variantsOpen => !variantsOpen)

  return (
    <div className='TierPriceEditor__ShopifyEntity'>
      <div className='TierPriceEditor__ShopifyEntity--customActions'>
        {
          customActions.map(
            (action, index) => (
              <Button
                id={shopifyId}
                key={shopifyId + ':' + index}
                children={action.name}
                accessibilityLabel={`Item action ${action.name}`}
                onClick={action.onAction}
                icon={DeleteMinor}
                // plain
              />
            )
          )
        }
      </div>
      <ResourceList.Item
        id={shopifyId + ':' + title}
        onClick={onClick}
        accessibilityLabel={`View details for ${title}`}
        shortcutActions={shortcutActions}
      >
        <div className='TierPriceEditor__ShopifyEntity--content'>
          <div className='TierPriceEditor__ShopifyEntity--main'>
            {infoBlock}
            {discTypeBlock}
          </div>
        </div>
      </ResourceList.Item>
      {
        _.isUndefined(variants) || variants.length === 0 || (variants.length === 1 && variants[0].isOnlyVariant) // don't show default variant
          ? null
          : <div className='TierPriceEditor__ShopifyEntity--variants'>
            <Button onClick={toggleVariantsOpen} aria-expanded={variantsOpen}>
                Variants
            </Button>
            <Collapsible open={variantsOpen} id='variants-collapsible'>
              <ResourceList
                items={variants}
                renderItem={
                  (item) => {
                    return (
                      <ShopifyVariantListItem key={item.id} {...item} singleVariant={variants.length === 1} customActions={[
                        {
                          name: '',
                          onAction: onVariantAction,
                        },
                      ]}
                      />
                    )
                  }
                }
              />
            </Collapsible>
          </div>
      }
    </div>
  )
}

ShopifyEntityListItem.propTypes = {
  id: PropTypes.any,
  shopifyId: PropTypes.any,
  title: PropTypes.string,
  onClick: PropTypes.func,
  onVariantAction: PropTypes.func,
  variants: PropTypes.array,
  customActions: PropTypes.array,
  shortcutActions: PropTypes.array,
}
