import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'

import { RootContext } from '@/components'
import { CampaignContext, SET_CAMPAIGN_ACTION, SET_STATE_ACTION, TierItem } from '@/components/editor'
import { getTierTemplate } from '@/components/utilities'
import { hasErrorStates } from '@/components/editor/sections'

export const CampaignLevels = (props) => {

  const rootContext = useContext(RootContext)
  const campaignContext = useContext(CampaignContext)

  const { campaign, dispatch } = campaignContext

  const { fieldErrors } = props

  const bulkInsertTiers = (bulk) => {
    pricelogger('Campaign bulkInsertTiers')
    const breakStart = parseFloat(bulk.breakStart)
    const breakStep = parseFloat(bulk.ascending ? bulk.breakStep : -bulk.breakStep)
    const discountStep = parseFloat(bulk.ascending ? bulk.discountStep : -bulk.discountStep)
    const discountStart = parseFloat(bulk.discountStart)
    const clonedCampaign = _.cloneDeep(campaign)
    const tiers = clonedCampaign.tiers
    let prefix = 'Buy'
    let infix = 'Get'
    let suffix = 'Off'
    if (!_.isEmpty(tiers)) {
      prefix = tiers[0].prefix
      infix = tiers[0].infix
      suffix = tiers[0].suffix
    }

    const block = new Array(parseInt(bulk.tiersNumber))
    let newTiers = Array.from(block, (v, k) => getTierTemplate(clonedCampaign.id, breakStart + k * breakStep, discountStart + k * discountStep, prefix, infix, suffix))
      .filter(tier => (parseFloat(tier.prerequisiteQuantity) > 0) && (parseFloat(tier.value) > 0))

    if (_.isEmpty(newTiers)) {
      rootContext.showNotification('Cannot add tiers. Check the add parameters', true)
      return
    }

    const maxValue = tiers.map(tier => parseFloat(tier.value)).reduce((a, b) => Math.max(a, b), -1)
    newTiers[0].value = Math.max(maxValue + 1, parseFloat(newTiers[0].value)) + '' // convert to string
    newTiers = newTiers.filter(v => {
      return tiers.findIndex(tier => (tier.prerequisiteQuantity === v.prerequisiteQuantity) || (tier.value === v.value)) < 0
    }) // keep unique only

    clonedCampaign.tiers = [...tiers, ...newTiers]
    const fieldErr = fieldErrors
    fieldErr.tiers = ''
    const hasErrors = hasErrorStates(fieldErr)
    dispatch({
      type: SET_STATE_ACTION, payload: {
        isDirty: true,
        campaign: clonedCampaign,
        fieldErrors: fieldErr,
        hasErrors,
      }
    })

    rootContext.showNotification('Added ' + newTiers.length + ' tiers')
  }

  /**
   * Lifted from the TierItem - it is "onUpdate" property there.
   * @param action
   */
  const tierAction = (action) => {
    pricelogger('tierAction')
    pricelogger(action)
    if (action.inserted) {
      return bulkInsertTiers(action.value)
    }

    const clonedCampaign = _.cloneDeep(campaign)
    const tiers = clonedCampaign.tiers
    if (action.deleted) {
      clonedCampaign.tiers = tiers.filter(tier => tier.id !== action.from)
      dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
      rootContext.showNotification('Deleted')
      return
    }

    const subject = tiers.find(tier => tier.id === action.from)
    if (action.update === 'value') {
      subject.value = action.value
    }
    if (action.update === 'prerequisiteQuantity') {
      subject.prerequisiteQuantity = action.value
    }
    if (['prefix', 'infix', 'suffix'].includes(action.update)) {
      subject[action.update] = action.value
    }

    if (!subject.justAdded) {
      subject.updated = true
    }

    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  return (
    <div className='CampaignLevels'>
      <div className='TierPriceEditor__Tiers'>
        {
          _.isUndefined(campaign.tiers) || _.isEmpty(campaign.tiers)
            ? null
            : (
              campaign.tiers.sort((a, b) => {
                if (parseFloat(a.prerequisiteQuantity).toFixed(4) === parseFloat(b.prerequisiteQuantity).toFixed(4)) {
                  return parseFloat(a.value) - parseFloat(b.value)
                }
                return parseFloat(a.prerequisiteQuantity) - parseFloat(b.prerequisiteQuantity)
              }).map((tier, idx, tiers) => {
                  return <TierItem key={tier.id} discountType={campaign.discountType}
                                   prerequisiteCount={campaign.prerequisiteCount}
                                   isPrice={campaign.isPrice}
                                   moneyFormat={campaign.moneyFormat}
                                   onUpdate={tierAction}
                                   index={idx}
                                   lastOne={idx === tiers.length - 1}
                                   {...tier}
                  />
                }
              )
            )
        }
      </div>
    </div>
  )
}

CampaignLevels.propTypes = {
  fieldErrors: PropTypes.any,
}
