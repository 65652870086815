import React, { useState, useEffect } from 'react'
import { Card, FooterHelp, Link, TextContainer, Subheading, Collapsible } from '@shopify/polaris'
import * as PropTypes from 'prop-types'

import { Media, Player } from 'react-media-player'

export const AppSupport = (props) => {
  const { location } = props
  const [createOfferVideoActive, setCreateOfferVideoActive] = useState(true)
  const [editSettingsVideoActive, setEditSettingsVideoActive] = useState(true)
  const [patchThemeVideoActive, setPatchThemeVideoActive] = useState(true)

  return (
    <div className='Support__Wrapper'>
      <Card sectioned title='Help Center &mdash; Mamuta Apps'>

        <Card.Section title='How To'>
          <TextContainer>
            <Subheading element={'h4'}>Create New Campaign</Subheading>
            <p>
                To set up the Smarty discounts on any of your products you need to create a Campaign. While
                creating a campaign you can choose if it will be a price or amount break discount, will it be a
                percentage discount or a fixed sum and so on.{' '}
              <Link onClick={
                () => {
                  setCreateOfferVideoActive(prev => !prev)
                }
              }>
                  Toggle video</Link>.
            </p>
            <Collapsible open={createOfferVideoActive} id='createCampaignVideo-collapsible'>
              <div className='Support__Media'>
                <Media>
                  <div className='Support__Media--Player'>
                    <Player src='https://www.youtube.com/embed/4E_KtvyvAu4?rel=0&amp;controls=0&amp;showinfo=0' vendor='youtube'/>
                  </div>
                </Media>
              </div>
            </Collapsible>
            <Subheading element={'h4'}>Edit Settings</Subheading>
            <p>
                If you need to edit ANY of Smarty app text that appears on your Product page or cart you can do it easy
                going to the Settings section. Here is a short video to explain how to do it.{' '}
              <Link onClick={
                () => {
                  setEditSettingsVideoActive(prev => !prev)
                }
              }>Toggle video</Link>
            </p>
            <Collapsible open={editSettingsVideoActive} id='editSettingsVideo-collapsible'>
              <div className='Support__Media'>
                <Media>
                  <div className='Support__Media--Player'>
                    <Player src='https://www.youtube.com/embed/EYiQ3_IF0Cs?rel=0&amp;controls=0&amp;showinfo=0' vendor='youtube'/>
                  </div>
                </Media>
              </div>
            </Collapsible>
            <Subheading element={'h4'}>Patch Theme</Subheading>
            <p>
                Sometimes the Template you are using is not ready for Smarty App to start working. We made it simple
                for you to fix it in 1 click! So, if you don’t see the campaigns in your store (and the campaigns are Enabled in the
                Dashboard) OR if you started to use a new Template after installing the Smarty App &mdash; you need to Patch
                the theme.{' '}
              <Link onClick={
                () => {
                  setPatchThemeVideoActive(prev => !prev)
                }
              }>Toggle video</Link>.
            </p>
            <Collapsible open={patchThemeVideoActive} id='patchThemeVideo-collapsible'>
              <div className='Support__Media'>
                <Media>
                  <div className='Support__Media--Player'>
                    <Player src='https://youtube.com/embed/YUrJ7yquOxk?rel=0&amp;controls=0&amp;showinfo=0' vendor='youtube'/>
                  </div>
                </Media>
              </div>
            </Collapsible>
          </TextContainer>
        </Card.Section>
        <Subheading element={'h4'}>If you still experience any issue, fee free to contact us!</Subheading>
        <FooterHelp>
          <p>
              Email: <Link url='mailto:support@smartydiscounts.com' external>support@smartydiscounts.com</Link>
          </p>
          <p>
              Documentation: <Link url='https://docs.mamuta.com/' external>https://docs.smartydiscounts.com</Link>
          </p>
        </FooterHelp>
      </Card>
    </div>
  )
}

AppSupport.propTypes = {
  location: PropTypes.object.isRequired,
}
