import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'
import { Checkbox, Tooltip, Select } from '@shopify/polaris'

import { CampaignContext, SET_CAMPAIGN_ACTION } from '@/components/editor'

export const CAMPAIGN_FOR_ORDER = 'order'
export const CAMPAIGN_FOR_PRODUCTS = 'products'
export const CAMPAIGN_FOR_COLLECTIONS = 'collections'

export const CampaignTypeSelector = (props) => {

  const campaignContext = useContext(CampaignContext)
  const { campaign, dispatch } = campaignContext

  const changeCampaignOption = (option, value) => {
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign[option] = value
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  const appliesToTypes = [
    { label: 'Entire order', value: CAMPAIGN_FOR_ORDER, disabled: campaign.isPrice },
    { label: 'Specific collections', value: CAMPAIGN_FOR_COLLECTIONS, disabled: false },
    { label: 'Specific products', value: CAMPAIGN_FOR_PRODUCTS, disabled: false },
  ]

  const handleChangeAppliesTo = (selected, id) => {
    changeCampaignOption('appliesTo', selected)
  }

  const handleChangeIsBundle = (value) => {
    changeCampaignOption('isBundle', value)
  }

  return (
    <>
      <div className='CampaignType__Selector'>
        <Tooltip
          content={'Campaign type "Entire order" is disabled if one of the "Price per item" product page tables is selected'}
          preferredPosition={'above'}
        >
          <Select
            label='Type: '
            labelInline
            options={appliesToTypes}
            onChange={handleChangeAppliesTo}
            value={_.isUndefined(campaign.appliesTo) ? CAMPAIGN_FOR_ORDER : campaign.appliesTo}
          />
        </Tooltip>
      </div>
      <div className='CampaignType__Bundle'>
        <Tooltip preferredPosition={'above'}
                 content={campaign.isPrice ? 'Disabled if one of the "Price per item" tables is selected' : 'The success messages will be disabled!'}>
          <Checkbox
            disabled={_.isUndefined(campaign.appliesTo) || (campaign.appliesTo === CAMPAIGN_FOR_ORDER) || campaign.isPrice}
            checked={campaign.isBundle}
            label='Bundle Products'
            helpText='Count all selected products/collections and/or variants per level. Success messages will not appear in the cart.'
            onChange={handleChangeIsBundle}
          />
        </Tooltip>
      </div>
    </>
  )
}