import React from 'react'

export const BasicPlusTable = () => {
  return (
    <div className='ProductPageTables'>
      <h4>Table Header</h4>
      <div className='ProductPageTables__Description mamuta-smarty-camp-descr'>
        <p>
          Campaign Description (may be a link)
        </p>
      </div>
      <div className='ProductPageTables__OrderWarning mamuta-smarty-camp-warning'>
        <em>Entire Order Warning</em>
      </div>
      <div className='mamuta-smarty-camp-container'>
        <div className='mamuta-smarty-camp-row2'>
          <div className='mamuta-smarty-camp-column'>
            Breaks column header
          </div>
          <div className='mamuta-smarty-camp-column'>
            Discounts column header
          </div>
        </div>
        <div className='mamuta-smarty-camp-row2'>
          <div className='mamuta-smarty-camp-column'>
            <span className='mamuta-smarty-camp-prefix'>Prefix </span>
            <span>100</span>
            <span> +</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span className='mamuta-smarty-camp-infix'>Infix </span>
            <span className='mamuta-smarty-camp-breaks-value-bump'>50</span>
            <span> %</span>
            <span className='mamuta-smarty-camp-suffix'> Suffix</span>
          </div>
        </div>
        <div className='mamuta-smarty-camp-row2 mamuta-smarty-camp-some'>
          <div className='mamuta-smarty-camp-column'>
            <span/>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span>Hidden breaks warning</span>
          </div>
        </div>
        <div className='mamuta-smarty-camp-row2'>
          <div className='mamuta-smarty-camp-column'>
            <span className='mamuta-smarty-camp-prefix'>Prefix </span>
            <span>200</span>
            <span> +</span>
          </div>
          <div className='mamuta-smarty-camp-column'>
            <span className='mamuta-smarty-camp-infix'>Infix </span>
            <span className='mamuta-smarty-camp-breaks-value-bump'>75</span>
            <span> %</span>
            <span className='mamuta-smarty-camp-suffix'> Suffix</span>
          </div>
        </div>
      </div>
    </div>
  )
}
