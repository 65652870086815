import React, { useContext } from 'react'
import { Context } from '@shopify/app-bridge-react'
import { getSessionToken } from '@shopify/app-bridge-utils';
import { VueWrapper } from 'vuera'
import Dashboard from '@/vue/Dashboard.vue'
import { ResourcePicker } from '@shopify/app-bridge/actions'

export const ReactCampaignDashboard = (props) => {
  const appBridge = useContext(Context)
  // props.appBridge = appBridge
  let myProps={...props}
  /*window.axios.interceptors.request.use((config) => {
          return getSessionToken(appBridge)
              .then((token) => {
                  config.headers['Authorization'] = `Bearer ${token}`;
                  return config;
              });
      }
  );*/
  myProps.appBridge = appBridge

  return (
    <div>
      <VueWrapper
        component={Dashboard}
        message='Hello from Vue!'
        {...myProps}
      />
    </div>
  )
}
