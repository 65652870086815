import { Moments } from '@/components/utilities'

/**
 *
 * @param parentId
 * @param prerequisiteQuantity
 * @param value
 * @param prefix
 * @param infix
 * @param suffix
 * @returns {{justAdded: boolean, prefix: *, prerequisiteQuantity: string, id: number, infix: *, suffix: *, value: string, updated: boolean, parentId: *}}
 */
export const getTierTemplate = (parentId, prerequisiteQuantity, value, prefix, infix, suffix) => {
  return {
    id: _.now() + _.random(1000),
    parentId: parentId,
    prerequisiteQuantity: prerequisiteQuantity + '',
    value: value + '',
    justAdded: true,
    updated: false,
    prefix,
    infix,
    suffix,
  }
}

/**
 *
 * @param shopSettings
 * @returns {{isBundle: boolean, settings: *, tiers: [{justAdded: boolean, prefix: *, prerequisiteQuantity: string, id: number, infix: *, suffix: *, value: string, updated: boolean, parentId: *}], tz: null, appliesTo: string, description: null, isEachItem: boolean, title: string, isActive: boolean, products: [], customersTag: string, isForCustomers: boolean, collections: [], isPrice: boolean, startsAt: *, discountType: string, moneyFormat: string, id: number, prerequisiteCount: string, endsAt: null}}
 */
export const getTierPriceTemplate = (shopSettings) => {
  let tmpl = {
    id: 0, // Important!
    title: '',
    appliesTo: 'order',
    description: null,
    isActive: false,
    isBundle: false,
    isForCustomers: false,
    isPrice: false,
    isEachItem: false,
    customersTag: '',
    prerequisiteCount: 'quantity',
    discountType: 'percent',
    tiers: [
      getTierTemplate(0,3, 5, 'Buy', 'Get', 'Off')
    ],
    collections: [],
    products: [],
    moneyFormat: '{{amount}}',
    startsAt: Moments.getCurrentTime(Moments.TIMEZONE_GMT),
    endsAt: null,
    tz: Moments.getLocalTZ(null),
    settings: _.cloneDeep(shopSettings)
  }
  tmpl.settings.productTableHandle = 'basic'
  return tmpl
}
