import React from 'react'
import * as PropTypes from 'prop-types'
import { DescriptionList } from '@shopify/polaris'

import { Strings } from '@/components/utilities'

export const AppInfo = (props) => {
  const { name, shopUrl, version, installed, theme, scope, webhooks } = props

  return (
    <DescriptionList
      items={[
        {
          term: 'Version',
          description:
              `Application "${name}" version ${version} installed ${installed} on ${Strings.getHostName(shopUrl)}`,
        },
        {
          term: 'Theme snippet',
          description:
              `${theme}`,
        },
        {
          term: 'Access scope',
          description:
              `${scope}`,
        },
        {
          term: 'Web hooks subscription',
          description:
              `${webhooks}`,
        },
      ]}
    />
  )
}

AppInfo.propTypes = {
  name: PropTypes.string,
  shopUrl: PropTypes.string,
  version: PropTypes.string,
  installed: PropTypes.string,
  theme: PropTypes.string,
  scope: PropTypes.string,
  webhooks: PropTypes.string,
}
