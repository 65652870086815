import React from 'react'
import * as PropTypes from 'prop-types'
import { DescriptionList } from '@shopify/polaris'

export const StatInfo = (props) => {
  const { total, running, disabled, forEntireOrder, forProducts, forCollections, products, variants, collections } = props
  return (
    <DescriptionList
      items={[
        {
          term: 'Running campaigns',
          description:
              `${running}`,
        },
        {
          term: 'Deactivated campaigns',
          description:
              `${disabled}`,
        },
        {
          term: 'Entire Order campaigns',
          description:
              `${forEntireOrder}`,
        },
        {
          term: 'Product campaigns',
          description:
              `${forProducts} campaigns for ${products} products with ${variants} variants`,
        },
        {
          term: 'Collection campaigns',
          description:
              `${forCollections} campaigns for ${collections} collections`,
        },
      ]}
    />
  )
}

StatInfo.propTypes = {
  total: PropTypes.number,
  running: PropTypes.number,
  disabled: PropTypes.number,
  forEntireOrder: PropTypes.number,
  forProducts: PropTypes.number,
  forCollections: PropTypes.number,
  products: PropTypes.number,
  variants: PropTypes.number,
  collections: PropTypes.number,
}
