import * as components from '@/components'
import * as editor from '@/components/editor'
import React from 'react'
// import Vue from 'vue'
import { TutorialModal } from '@/components/dashboard'
/*
import { VueWrapper } from 'vuera'
import Dashboard from '@/vue/Dashboard.vue'
*/
// console.log(components)
let componentProp = {}
export const setRouteComponentProp = (prop) => { componentProp = prop }
export const addRouteComponentProp = (prop) => { componentProp = _.merge(componentProp, prop) }

// props (in render property) is the properties from the Route component:
// history, location, and match objects (see https://reacttraining.com/react-router/web/api/Route/route-props)
// componentProp is any props, which set by AppRoot component using exported function setRouteComponentProp
export var routes = [
  {
    key: 'dashboard',
    exact: true,
    path: '/',
    render: (props) => <components.ReactCampaignDashboard {...props} {...componentProp}/>,
    navbar: true,
  },
  {
    key: 'campaigns',
    exact: false,
    path: '/tierprices',
    render: (props) => <components.AppCampaigns {...props} {...componentProp}/>,
    navbar: true,
  },
  {
    key: 'editor',
    exact: false,
    path: '/edit/:id',
    render: (props) => <components.ReactCampaignWizard {...props} {...componentProp}/>,
    navbar: false,
  },
  {
    key: 'about',
    exact: false,
    path: '/about',
    render: (props) => <components.AboutApp {...props} {...componentProp}/>,
    navbar: true,
  },
  {
    key: 'support and How To',
    exact: false,
    path: '/support',
    render: (props) => <components.AppSupport {...props} {...componentProp}/>,
    navbar: true,
  },
  {
    key: 'tutorial',
    exact: false,
    path: '/tutorial',
    render: (props) => <TutorialModal {...props} {...componentProp}/>,
    navbar: false,
  },

]
