import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'
import { Context } from '@shopify/app-bridge-react'

import {
  TextField,
  Heading, TextStyle, Button,
} from '@shopify/polaris'

import { RootContext } from '@/components'
import { CampaignContext, SET_CAMPAIGN_ACTION, SET_STATE_ACTION } from '@/components/editor'
import { gotoThemeCustomization, hasErrorStates } from '@/components/editor/sections'

export const CampaignGeneral = (props) => {
  const appBridge = useContext(Context)
  const rootContext = useContext(RootContext)
  const campaignContext = useContext(CampaignContext)
  const { campaign, dispatch } = campaignContext

  const { fieldErrors, themeId, history } = props

  const changeCampaignOption = (option, value) => {
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign[option] = value
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
  }

  const handleChangeTitle = (value) => {
    const fieldErr = _.cloneDeep(fieldErrors)
    fieldErr.title = _.isEmpty(value) ? 'Should not be empty' : ''
    const hasErrors = hasErrorStates(fieldErr)
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign.title = value
    dispatch({
      type: SET_STATE_ACTION, payload: {
        campaign: clonedCampaign,
        isDirty: true,
        fieldErrors: fieldErr,
        hasErrors,
      }
    })
  }

  const handleGenerateTitle = () => {
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign.title = 'TP_' + _.now()
    const fieldErr = _.cloneDeep(fieldErrors)
    fieldErr.title = ''
    const hasErrors = hasErrorStates(fieldErr)
    dispatch({
      type: SET_STATE_ACTION, payload: {
        campaign: clonedCampaign,
        isDirty: true,
        fieldErrors: fieldErr,
        hasErrors,
      }
    })
  }

  const handleChangeIsActive = (value) => {
    changeCampaignOption('isActive', !campaign.isActive)
  }

  const handleChangeDescription = (value) => {
    changeCampaignOption('description', _.isEmpty(value) ? null : value)
  }

  const duplicateCampaign = () => {
    const clonedCampaign = cloneCampaign()
    rootContext.setItem(clonedCampaign)
    dispatch({ type: SET_CAMPAIGN_ACTION, payload: clonedCampaign })
    rootContext.showNotification('Campaign duplicated')
    history.push('/edit/0')
  }

  const cloneCampaign = () => {
    const clonedCampaign = _.cloneDeep(campaign)
    clonedCampaign.id = 0
    clonedCampaign.isActive = false
    clonedCampaign.title = clonedCampaign.title + ' DUPLICATE'
    for (let i = 0; i < clonedCampaign.tiers.length; ++i) {
      clonedCampaign.tiers[i].id = _.now() + _.random(1000)
      clonedCampaign.tiers[i].parentId = 0
      clonedCampaign.tiers[i].justAdded = true
      clonedCampaign.tiers[i].updated = false
    }
    return clonedCampaign
  }

  return (
    <div className='CampaignGeneral'>
      <Heading>General</Heading>
      <span className={'CampaignCurrentStatus ' + (campaign.isActive ? 'CampaignActive' : '')}>
              <TextStyle
                variation={campaign.isActive ? 'strong' : 'negative'}>{'Campaign Is ' + (campaign.isActive ? 'Active' : 'Inactive')}</TextStyle>
            </span>
      <div className='CampaignName'>
        <TextField
          label='Campaign Title (customers do not see it)'
          value={_.isUndefined(campaign.title) ? '' : campaign.title}
          onChange={handleChangeTitle}
          labelAction={
            {
              content: 'Generate',
              onAction: handleGenerateTitle
            }
          }
          error={fieldErrors.title}
        />
      </div>
      <div className='CampaignStatus'>
        <Button fullWidth primary={campaign.isActive} destructive={!campaign.isActive}
                onClick={handleChangeIsActive}>{(campaign.isActive ? 'Deactivate' : 'Activate') + ' campaign'}</Button>
      </div>
      <div className='CampaignShowOn'>
        <Button fullWidth primary external onClick={() => gotoThemeCustomization(themeId, appBridge)}
                disabled={_.isEmpty(themeId) || campaign.id === 0}>
          Customise Visual Settings
        </Button>
      </div>
      <div className='CampaignDescription'>
        <TextField
          label='Campaign Description (IMPORTANT: visible to the customers!)'
          value={_.isNull(campaign.description) || _.isUndefined(campaign.description) ? '' : campaign.description}
          onChange={handleChangeDescription}
          multiline
        />
      </div>
      <div className='CampaignDuplicate'>
        <Button primary
                disabled={campaign.id === 0}
                onClick={duplicateCampaign}>
          {'Duplicate campaign'}
        </Button>
      </div>
    </div>
  )
}

CampaignGeneral.propTypes = {
  history: PropTypes.object.isRequired,
  fieldErrors: PropTypes.any,
  themeId: PropTypes.any,
}
