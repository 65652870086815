// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react'
import { Badge } from '@shopify/polaris'
import moment from 'moment'
import 'moment-timezone'
import 'moment-timezone/moment-timezone-utils.js'
import * as tzData from 'moment-timezone/data/packed/latest.json'

export default class Moments {
  static DATE_FORMAT = 'YYYY-MM-DD'
  static TIME_FORMAT = 'HH:mm'
  static DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
  static TIMEZONE_GMT = 'Etc/UTC'

  static getLocalTZ (tz = null) {
    return _.isNil(tz) ? moment.tz.guess() : tz
  }

  static getCurrentMomentGMT () {
    return moment().tz(Moments.TIMEZONE_GMT)
  }

  static getCurrentMomentLocal (zone) {
    return Moments.getCurrentMomentGMT().tz(Moments.getLocalTZ(zone))
  }

  static getCurrentTime (tz = null) {
    return Moments.getCurrentMomentLocal(tz).format(Moments.DATE_TIME_FORMAT)
  }

  static convertToLocal (time, zone) {
    return moment.tz(time, Moments.DATE_TIME_FORMAT, Moments.TIMEZONE_GMT).tz(Moments.getLocalTZ(zone))
  }

  static convertToGMT (time, zone) {
    return moment.tz(time, Moments.DATE_TIME_FORMAT, Moments.getLocalTZ(zone)).tz(Moments.TIMEZONE_GMT)
  }

  static makeLocalMoment (time, zone) {
    return moment.tz(time, Moments.DATE_TIME_FORMAT, Moments.getLocalTZ(zone))
  }

  static makeLocalTime (time, tz, isEndTime = false) {
    if (isEndTime) {
      return _.isNull(time) ? null : Moments.convertToLocal(time, tz).format(Moments.DATE_TIME_FORMAT)
    } else {
      return _.isNull(time) ? Moments.getCurrentTime(tz) : Moments.convertToLocal(time, tz).format(Moments.DATE_TIME_FORMAT)
    }
  }

  static isExpired (current, endsAt) {
    return current.isSameOrAfter(endsAt)
  }

  static isPending (current, startsAt) {
    return current.isBefore(startsAt)
  }

  static isRunning (current, startsAt, endsAt) {
    return !(Moments.isExpired(current, endsAt) || Moments.isPending(current, startsAt))
  }

  static getBadge (isActive, startsAt, endsAt, tz) {
    const zone = Moments.getLocalTZ(tz)
    const current = Moments.getCurrentMomentLocal(zone)
    const distant = current.clone().add(10, 'years')
    const starts = _.isNull(startsAt) ? current : moment.tz(startsAt, Moments.DATE_TIME_FORMAT, zone)
    const ends = _.isNull(endsAt) ? distant : moment.tz(endsAt, Moments.DATE_TIME_FORMAT, zone)
    const expired = Moments.isExpired(current, ends)
    const notReady = Moments.isPending(current, starts)
    const running = !(expired || notReady)
    return (
      isActive && running
        ? <Badge status={'success'} progress={'partiallyComplete'}>active</Badge>
        : !isActive
          ? <Badge>inactive</Badge>
          : expired ? <Badge status='warning' progress={'complete'}>expired</Badge>
            : <Badge status='info' progress={'incomplete'}>pending</Badge>
    )
  }

  static getTzOptions () {
    /* will link all zones with the same offset
        const unpackedTz = tzData.zones.map(zone => moment.tz.unpack(zone))
        const linkedTz = moment.tz.filterLinkPack(
          {
            zones: unpackedTz,
            links: tzData.links,
            vergion: tzData.version,
          },
          2018, 2018)
          */
    return tzData.zones
      .map(zone => moment.tz.unpack(zone).name)
      .filter((v, idx, self) => self.indexOf(v) === idx) // return unique elements of array
      .sort()
      .map(name => {
        return { label: name, value: name }
      })
  }
}
